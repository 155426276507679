import axiosInstance from '@/api/axiosInstance';

const actions = {
  async register({ commit }, payload) {
    try {
      const url = '/socios/register';

      const response = await axiosInstance.post(url, payload);

      if (response?.status === 200) {
        const data = response.data;

        if (data.status) {
          const timestamp = new Date().getTime();
          localStorage.setItem('partnerToken', data.data.token);
          localStorage.setItem('partnerTokenTime', timestamp);
        }

        commit('SET_DATA', { key: 'formSend', data: true });
        return data;
      } else {
        throw new Error('No se pudo obtener la información de la api.');
      }
    } catch (error) {
      console.error('Error al crear la petición:', error);
      throw error;
    }
  },
  async login({ commit }, payload) {
    try {
      const url = '/socios/login';

      const response = await axiosInstance.post(url, payload);

      if (response?.status === 200) {
        const data = response.data;

        if (data.status) {
          const timestamp = new Date().getTime();
          localStorage.setItem('partnerToken', data.data.token);
          localStorage.setItem('partnerTokenTime', timestamp);
        }

        commit('SET_DATA', { key: 'formSend', data: true });
        return data;
      } else {
        throw new Error('No se pudo obtener la información de la api.');
      }
    } catch (error) {
      console.error('Error al crear la petición:', error);
      throw error;
    }
  },
  async save({ commit }, payload) {
    try {
      const url = '/socios/save';

      const response = await axiosInstance.post(url, payload);

      if (response?.status === 200) {
        const data = response.data;

        if (data.status) {
          commit('SET_DATA', { key: 'partner', data: data.data });
        }

        commit('SET_DATA', { key: 'formSend', data: true });
        return data;
      } else {
        throw new Error('No se pudo obtener la información de la api.');
      }
    } catch (error) {
      console.error('Error al crear la petición:', error);
      throw error;
    }
  },
  async get({ commit }, payload) {
    try {
      const url = '/socios/get';

      const response = await axiosInstance.post(url, payload);

      if (response?.status === 200) {
        const data = response.data;

        if (data.status) {
          commit('SET_DATA', { key: 'partner', data: data.data });
        }

        commit('SET_DATA', { key: 'formSend', data: true });
        return data;
      } else {
        throw new Error('No se pudo obtener la información de la api.');
      }
    } catch (error) {
      console.error('Error al crear la petición:', error);
      throw error;
    }
  },
  async recovery({ commit }, payload) {
    try {
      const url = '/socios/forgot_password';

      const response = await axiosInstance.post(url, payload);

      if (response?.status === 200) {
        const data = response.data;

        commit('SET_DATA', { key: 'formSend', data: true });
        return data;
      } else {
        throw new Error('No se pudo obtener la información de la api.');
      }
    } catch (error) {
      console.error('Error al crear la petición:', error);
      throw error;
    }
  },
};

export default actions;