<template>
  <section>
    <h2 class="title is-3 has-text-centered">Interacciones</h2>
    <b-tabs v-model="activeTab" :multiline="true" position="is-centered" class="block">
      <b-tab-item v-for="tab in filteredTabs" :key="tab.id" :value="tab.id" :label="getTabLabel(tab.id)" :headerClass="tab.headerClass">
        <component :is="tab.component" :type="tab.id" :label="tab.label" />
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
import Interactions from '../components/index.vue';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      activeTab: 'toApprove',
      tabs: [
        { id: 'toApprove', label: 'Mis Pendientes', headerClass: 'has-background-danger has-text-white', component: Interactions, displayed: true },
        {
          id: 'createdByMe',
          label: 'Todas las Interacciones',
          headerClass: 'has-background-info has-text-white',
          component: Interactions,
          displayed: true,
        },
        { id: 'toClarify', label: 'Por Aclarar', headerClass: 'has-background-warning has-text-white', component: Interactions, displayed: true },
        { id: 'rejected', label: 'Rechazadas', headerClass: 'has-background-primary has-text-white', component: Interactions, displayed: true },
        { id: 'finalized', label: 'Concretadas', headerClass: 'has-background-success has-text-white', component: Interactions, displayed: true },
      ],
    };
  },
  computed: {
    ...mapState({
      toApprove: (state) => state.interactions.toApprove.pagination.total || 0,
      createdByMe: (state) => state.interactions.createdByMe.pagination.total || 0,
      rejected: (state) => state.interactions.rejected.pagination.total || 0,
      finalized: (state) => state.interactions.finalized.pagination.total || 0,
      toClarify: (state) => state.interactions.toClarify.pagination.total || 0,
    }),

    // Filtramos las pestañas que deben mostrarse
    filteredTabs() {
      return this.tabs.filter((tab) => tab.displayed);
    },
  },
  methods: {
    getTabLabel(tabId) {
      const totals = {
        toApprove: this.toApprove,
        createdByMe: this.createdByMe,
        rejected: this.rejected,
        finalized: this.finalized,
        toClarify: this.toClarify,
      };
      const tab = this.tabs.find((tab) => tab.id === tabId);
      return `${tab.label} (${totals[tabId] || 0})`;
    },
  },
};
</script>

<style>
.tabs li.is-active a {
  color: inherit !important;
}
.b-tabs .tabs li a {
  color: inherit !important;
}

@media screen and (max-width: 1023px) {
  .b-tabs .tabs {
    padding: 0 10px;
  }
  
  .tabs ul {
    display: block !important;
  }
}
</style>
