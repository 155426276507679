<template>
  <section class="section-padding">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-6">
          <div class="is-flex is-justify-content-space-between is-align-items-center">
            <h1 class="title">Socios</h1>
          </div>
        </div>
        <div class="column is-6">
          <div class="buttons is-pulled-right">
            <b-button tag="router-link" :to="{ name: 'interactions' }" type="is-primary" outlined> Interacciones </b-button>
          </div>
        </div>
        <div class="column is-12">
          <List></List>
        </div>
      </div>
    </div>

    <div class="modal form-sliders-modal" :class="{ 'is-active': showFormModal }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <h2 class="modal-card-title">Socio</h2>
          <button @click="showFormModal = false" class="delete" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <Form @close-form="closeForm"></Form>
        </section>
        <footer class="modal-card-foot is-justify-content-flex-end"></footer>
      </div>
    </div>
  </section>
</template>


<script>
import List from '../components/list.vue';
import Form from '../components/form.vue';

export default {
  components: {
    Form,
    List,
  },
  data() {
    return {
      showFormModal: false,
    };
  },
  methods: {
    closeForm() {
      this.showFormModal = false;
    },
  },
};
</script>

<style>
</style>