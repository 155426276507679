
const initialState = {
  list: [],
  pagination: [],
};

const mutations = {
  SET_DATA(state, { key, data }) {
    state[key] = data;
  },
  UPDATE_FIELD(state, { key, data }) {
    const keys = key.split('.');
    let current = state;

    keys.forEach((k, index) => {
      if (index === keys.length - 1) {
        current[k] = data;
      } else {
        current = current[k] = current[k] || {};
      }
    });
  },
  INIT_STATE(state) {
    state.list = initialState.list;
    state.pagination = initialState.pagination;
  },
};

export default mutations;