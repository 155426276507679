<template>
  <div>
    <form @submit.prevent="login" class="login-form">
      <h3 class="is-size-5 has-text-weight-bold has-text-centered mb-5">RECUPERAR CONTRASEÑA</h3>

      <b-field label="Correo electrónico">
        <b-input v-model="form.email" required type="email"></b-input>
      </b-field>

      <b-button class="is-primary" type="submit" native-type="submit" label="Enviar" expanded :loading="isLoading"></b-button>
    </form>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      form: {
        email: '', //salvador.navarro401@gmail.com
        password: '', //12345abcABC
      },
      sendForm: false,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions({
      recoveryPartner: 'auth/recovery',
    }),
    async login() {
      this.isLoading = true;

      const response = await this.recoveryPartner({
        email: this.form.email,
      });

      Swal.fire({
        icon: response.status ? 'success' : 'error',
        title: response.msg,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: true,
      });

      this.isLoading = false;

      if (response.status) {
        this.$router.push({ name: 'login' });
      }
    },
  },
};
</script>
