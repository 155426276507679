<template>
  <form id="FormularioCrud" @submit.prevent="sendForm">
    <b-field label="Invitado" label-position="horizontal">
      <b-input v-model="form.nombre" type="text" required disabled></b-input>
    </b-field>

    <b-field label="Correo" label-position="horizontal">
      <b-input v-model="form.email" type="text" required disabled></b-input>
    </b-field>

    <b-field label="Fecha que se llevó la interacción" label-position="horizontal">
      <b-input v-model="form.fecha_interaccion" type="date" required expanded></b-input>
    </b-field>

    <b-field label="Tipo de interaccion" label-position="horizontal">
      <b-select v-model="form.tipo_interaccion" required expanded placeholder="Nada seleccionado..." disabled>
        <option value="venta">Venta</option>
      </b-select>
    </b-field>

    <b-field label="Comentario" label-position="horizontal">
      <b-input v-model="form.comentarios_emisor" type="textarea" required placeholder="Escribir una síntesis de la reunión."></b-input>
    </b-field>

    <b-field label="Puntuación" label-position="horizontal">
      <b-rate v-model="form.puntuacion_emisor" icon-pack="fas" size="is-medium" spaced required></b-rate>
    </b-field>

    <b-field v-if="form.tipo_interaccion != 'business'" label="Monto" label-position="horizontal">
      <b-input v-model="form.monto" step="0.01" min="0" type="number" required></b-input>
    </b-field>

    <b-button class="is-success" type="submit" native-type="submit" label="Guardar" expanded></b-button>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Swal from 'sweetalert2';

export default {
  components: {},
  props: {
    datavalues: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState({
      partner: (state) => state.auth.partner,
    }),
  },
  watch: {
    datavalues: {
      immediate: true,
      handler(newValues) {
        this.loadFormData(newValues);
      },
    },
  },
  data() {
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 60);

    return {
      form: {},
      initialFormState: {
        nombre: '',
        email: '',
        emisor_id: null,
        tipo_interaccion: 'venta',
        invitado_id: null,
        comentarios_emisor: '',
        puntuacion_emisor: 0,
        monto: null,
        fecha_interaccion: null,
      },
      minDate: sevenDaysAgo.toISOString().split('T')[0], // Formato YYYY-MM-DD
      maxDate: today.toISOString().split('T')[0], // Formato YYYY-MM-DD
    };
  },
  methods: {
    ...mapActions({
      interaction: 'guests/interaction',
    }),
    async loadFormData() {
      if (this.datavalues && Object.keys(this.datavalues).length) {
        Object.keys(this.initialFormState).forEach((key) => {
          this.form[key] = this.datavalues[key] || this.initialFormState[key];
        });

        this.form.emisor_id = this.partner.id;
        this.form.invitado_id = this.datavalues.id;
        this.form.puntuacion_emisor = parseFloat(this.form.puntuacion_emisor);
        this.form.puntuacion_receptor = parseFloat(this.form.puntuacion_receptor);
        this.form.nombre =
          this.form.apellido != '' ? this.form.nombre.toUpperCase() + ' ' + this.form.apellido.toUpperCase() : this.initialFormState.nombre;
      } else {
        this.form = { ...this.initialFormState };
      }
    },
    async sendForm() {
      const payload = { ...this.form };

      if (payload.puntuacion_emisor <= 0) {
        Swal.fire({
          icon: 'error',
          title: 'Por favor selecciona una puntuación antes de enviar.',
          confirmButtonColor: '#268af7',
        });

        return;
      }

      const response = await this.interaction(payload);

      Swal.fire({
        icon: response.status ? 'info' : 'error',
        title: response.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: true,
      });

      if (response.status) {
        this.$store.commit('guests/SET_DATA', { key: 'reloadList', data: true });

        this.closeForm();
      }
    },
    closeForm() {
      this.$emit('close-form');
    },
  },
};
</script>

<style>
.custom-datetime-input input {
  display: block !important;
}
</style>
