// Import
import Vue from 'vue';
import Router from 'vue-router';
import Paths from './paths/index';

// Use Vue Router
Vue.use(Router);

// Start Vue Router
const router = new Router({
  mode: 'history', // Descomenta esta línea para habilitar el modo de historial
  routes: [
    ...Paths,
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// Middleware
router.beforeEach((to, from, next) => {
  if ((to.path === '/login/' || to.path === '/login')) {
    if(validatePartnerSession()) {
      return next('/partners');
    }
  }

  if ((to.path === '/register/' || to.path === '/register')) {
    if(validatePartnerSession()) {
      return next('/partners');
    }
  }

  if (to.path.includes('partners')) {
    if (!validatePartnerSession()) {
      return next('/login');
    }
  }

  next();
});

// Método para validar la sesión de Partner
function validatePartnerSession() {
  const partnerToken = localStorage.getItem('partnerToken');
  const partnerTokenTime = localStorage.getItem('partnerTokenTime');

  if (partnerToken && partnerTokenTime) {
    const currentTime = new Date().getTime();
    const sessionPartnerTimestamp = parseInt(partnerTokenTime, 10);
  
    // Verificar si el token ha expirado (más de 3 horas)
    if (currentTime - sessionPartnerTimestamp > 10800000) {
      localStorage.removeItem('partnerToken');
      localStorage.removeItem('partnerTokenTime');
      return false;
    }

    return true;
  }

  return false; // Si la sesión de partner es válida
}

// Export
export default router;
