<template>
  <form id="FormularioCrud" @submit.prevent="sendForm">
    <b-field label="Invitado" label-position="horizontal">
      <b-input v-model="form.nombre" type="text" required disabled></b-input>
    </b-field>

    <b-field label="RFC" label-position="horizontal">
      <b-input v-model="form.rfc" type="text" required disabled></b-input>
    </b-field>

    <b-field label="Correo" label-position="horizontal">
      <b-input v-model="form.email" type="text" required disabled></b-input>
    </b-field>

    <b-field label="Fecha que se llevó la interacción" label-position="horizontal">
      <b-input v-model="form.fecha_interaccion" type="date" required expanded></b-input>
    </b-field>

    <b-field label="Tipo de interaccion" label-position="horizontal">
      <b-select v-model="form.tipo_interaccion" required expanded placeholder="Nada seleccionado...">
        <option value="compra">Compra</option>
        <option value="referencia">Referencia</option>
        <option value="alianza">Alianza</option>
        <option value="business">Go Business</option>
      </b-select>
    </b-field>

    <div v-if="form.tipo_interaccion == 'referencia'" class="search">
      <b-field class="mb-3" :label="'Referenciado'" label-position="horizontal">
        <b-autocomplete
          v-model="form.selectedReferenciado"
          :data="searchReferableUsersList"
          :placeholder="'Referenciado'"
          field="empresa"
          icon-right="magnify"
          :loading="isFetching"
          :disabled="['toApprove', 'createdByMe', 'rejected', 'finalized'].includes(datavalues.formType)"
          @typing="handleTyping"
          @select="handleSelect"
          clearable
          expanded
        >
          <template slot-scope="props">
            <div class="media">
              <div class="media-content">
                <p>
                  Tipo:
                  <b v-if="esSocio(props.option)">Socio</b>
                  <b v-else>Invitado</b>
                </p>

                <p>
                  Nombre:
                  <b> {{ props.option.nombre }} {{ props.option.apellido }} </b>
                </p>

                <p>
                  Empresa:
                  <b>
                    {{ props.option.empresa }}
                  </b>
                </p>
              </div>
            </div>
          </template>
        </b-autocomplete>
      </b-field>
    </div>

    <b-field label="Comentario" label-position="horizontal">
      <b-input v-model="form.comentarios_emisor" type="textarea" required placeholder="Escribir una síntesis de la reunión."></b-input>
    </b-field>

    <b-field label="Puntuación" label-position="horizontal">
      <b-rate v-model="form.puntuacion_emisor" icon-pack="fas" size="is-medium" spaced required></b-rate>
    </b-field>

    <b-field v-if="!['alianza', 'business', 'referencia'].includes(form.tipo_interaccion)" label="Monto" label-position="horizontal">
      <b-input v-model="form.monto" step="0.01" min="0" type="number" required></b-input>
    </b-field>

    <b-button class="is-success" type="submit" native-type="submit" label="Guardar" expanded></b-button>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Swal from 'sweetalert2';

export default {
  components: {},
  props: {
    datavalues: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState({
      partner: (state) => state.auth.partner,
      searchReferableUsersList: (state) => state.interactions.searchReferableUsers,
    }),
  },
  watch: {
    datavalues: {
      immediate: true,
      handler(newValues) {
        this.loadFormData(newValues);
      },
    },
  },
  data() {
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 60);

    return {
      entityType: 'searchReferableUsers',
      form: {},
      initialFormState: {
        nombre: '',
        email: '',
        apellido: '',
        rfc: '',
        emisor_id: null,
        tipo_interaccion: 'compra',
        receptor_id: null,
        comentarios_emisor: '',
        puntuacion_emisor: 0,
        monto: null,
        fecha_interaccion: null,
        referenciado_id: '',
        referenciado_tipo: '',
        referenciado_empresa: '',
        selectedReferenciado: '',
      },
      minDate: sevenDaysAgo.toISOString().split('T')[0], // Formato YYYY-MM-DD
      maxDate: today.toISOString().split('T')[0], // Formato YYYY-MM-DD
      isFetching: false,
    };
  },
  methods: {
    ...mapActions({
      interaction: 'associates/interaction',
      searchReferableUsers: 'interactions/searchReferableUsers',
    }),
    async loadFormData() {
      if (this.datavalues && Object.keys(this.datavalues).length) {
        Object.keys(this.initialFormState).forEach((key) => {
          this.form[key] = this.datavalues[key] || this.initialFormState[key];
        });


        this.form.emisor_id = this.partner.id;
        this.form.receptor_id = this.datavalues.id;
        this.form.puntuacion_emisor = parseFloat(this.form.puntuacion_emisor);
        this.form.puntuacion_receptor = parseFloat(this.form.puntuacion_receptor);
        this.form.nombre =
          this.form.apellido != '' ? this.form.nombre.toUpperCase() + ' ' + this.form.apellido.toUpperCase() : this.initialFormState.nombre;
        this.form.rfc = this.form.rfc != '' ? this.form.rfc.toUpperCase() : this.initialFormState.rfc;
      } else {
        this.form = { ...this.initialFormState };
      }
    },
    async sendForm() {
      const payload = { ...this.form };

      if (payload.tipo_interaccion == 'referencia' && payload.referenciado_empresa != payload.selectedReferenciado) {
        Swal.fire({
          icon: 'error',
          title: 'Por favor selecciona un referenciado valido antes de enviar.',
          confirmButtonColor: '#268af7',
        });

        return;
      }

      if (payload.puntuacion_emisor <= 0) {
        Swal.fire({
          icon: 'error',
          title: 'Por favor selecciona una puntuación antes de enviar.',
          confirmButtonColor: '#268af7',
        });

        return;
      }

      delete payload.referenciado_empresa;
      delete payload.selectedReferenciado;

      const response = await this.interaction(payload);

      Swal.fire({
        icon: response.status ? 'info' : 'error',
        title: response.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: true,
      });

      if (response.status) {
        this.$store.commit('associates/SET_DATA', { key: 'reloadList', data: true });

        this.closeForm();
      }
    },
    closeForm() {
      this.$emit('close-form');
    },
    esSocio(row) {
      return row && Object.prototype.hasOwnProperty.call(row, 'jefe_circulo');
    },
    async handleTyping(value) {
      const trimmedValue = value.trim();
      const lengthWithoutSpaces = trimmedValue.length;

      if (lengthWithoutSpaces >= 3) {
        const newQuery = {
          search: trimmedValue,
          emisor_id: this.form.emisor_id,
          receptor_id: this.form.receptor_id,
        };

        this.isFetching = true;

        await this.searchReferableUsers(newQuery);

        this.isFetching = false;
      }
    },
    handleSelect(option) {
      if (option?.id) {
        const referenciado_tipo = Object.prototype.hasOwnProperty.call(option, 'jefe_circulo') ? 'socio' : 'invitado';
        this.form.referenciado_id = option.id;
        this.form.referenciado_tipo = referenciado_tipo;
        this.form.referenciado_empresa = option.empresa;
      }
    },
  },
};
</script>

<style>
.custom-datetime-input input {
  display: block !important;
}
.stars .icon {
  cursor: pointer;
  margin-right: 0.5rem;
}
</style>
