<template>
  <div class="container box">
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <h1 class="title">Mi perfil</h1>
        </div>
      </div>
      <div class="column is-12">
        <form id="FormularioCrud" @submit.prevent="sendForm">
          <div class="columns is-multiline">
            <div class="column is-6">
              <b-field label="Número de socio">
                <b-input v-model="form.socio" required disabled type="text"></b-input>
              </b-field>

              <b-field label="RFC">
                <b-input v-model="form.rfc" required disabled type="text"></b-input>
              </b-field>

              <b-field label="Empresa">
                <b-input v-model="form.company" required disabled type="text"></b-input>
              </b-field>

              <b-field label="Paquete">
                <b-input v-model="form.package" required disabled type="text"></b-input>
              </b-field>

              <b-field label="Vigencia">
                <b-input v-model="form.validity" required disabled type="text"></b-input>
              </b-field>

              <b-field label="Circulo">
                <b-input v-model="form.circle" required disabled type="text"></b-input>
              </b-field>
            </div>
            <div class="column is-6">
              <b-field label="Nombre" label-position="horizontal">
                <b-input v-model="form.name" disabled type="text"></b-input>
              </b-field>

              <b-field label="Apellido">
                <b-input v-model="form.lastName" required disabled type="text"></b-input>
              </b-field>

              <b-field label="Teléfono">
                <b-input v-model="form.phone" required disabled type="text"></b-input>
              </b-field>

              <b-field label="Correo electrónico">
                <b-input v-model="form.email" required disabled type="email"></b-input>
              </b-field>
              <div class="notification is-primary has-text-centered">
                <p>
                  Cualquier cambio deberá ser solicitado a <br />
                  <a href="mailto:reddenegocios@camaradecomerciogdl.mx">
                    <span class="has-text-weight-bold">reddenegocios@camaradecomerciogdl.mx</span></a
                  >
                </p>
              </div>
            </div>
          </div>

          <!--           <b-button class="is-success" type="submit" native-type="submit" label="Guardar" expanded></b-button>
 -->
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      form: {},
      initialFormState: {
        socio: '',
        rfc: '',
        company: '',
        name: '',
        lastName: '',
        phone: '',
        email: '',
        package: '',
        validity: '',
        circle: '',
      },
    };
  },
  computed: {
    ...mapState({
      partner: (state) => state.auth.partner,
    }),
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.loadFormData();
  },
  methods: {
    ...mapActions({
      getPartner: 'auth/get',
      submitForm: 'auth/save',
    }),
    async loadFormData() {
      await this.getPartner();

      if (this.partner && Object.keys(this.partner).length) {
        const { nombre, apellido, empresa, telefono, email, socio, rfc, paquete, vigencia, circulo } = this.partner;

        this.form = {
          name: nombre || this.initialFormState.name,
          lastName: apellido || this.initialFormState.lastName,
          company: empresa || this.initialFormState.company,
          email: email || this.initialFormState.email,
          phone: telefono || this.initialFormState.phone,
          socio: socio || this.initialFormState.socio,
          rfc: rfc || this.initialFormState.rfc,
          package: paquete || this.initialFormState.package,
          validity: vigencia || this.initialFormState.validity,
          circle: circulo || this.initialFormState.circle,
        };
      } else {
        this.form = { ...this.initialFormState };
      }
    },
    async sendForm() {
      const { ...rest } = this.form;

      const payload = {
        ...rest,
      };

      const response = await this.submitForm(payload);

      Swal.fire({
        icon: response.status ? 'info' : 'error',
        title: response.msg,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: true,
      });
    },
  },
};
</script>

<style scoped>
.form-horizontal {
  max-width: 600px;
  margin: auto;
}
</style>
