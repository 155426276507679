<template>
  <div class="banner-home">
    <b-carousel
      :indicator="indicator"
      :indicator-inside="indicatorInside"
      :indicator-style="indicatorStyle"
      :arrow-hover="arrowHover"
      :pause-info="pauseInfo"
      :icon-prev="iconPrev"
      :icon-next="iconNext"
      :icon-size="iconSize"
      :has-drag="true"
      :arrow="false"
    >
      <b-carousel-item v-for="(item, i) in items" :key="i">
        <template>
          <b-image v-once class="image" :src="item.image" role="button" tabindex="0"></b-image>
        </template>
        <div class="overlay-text">
          <div class="section-divider"></div>
          <h3 v-if="item.titulo">{{ item.titulo }}</h3>
          <p v-if="item.contenido">{{ item.contenido }}</p>
          <b-button v-if="item.url" tag="a" :href="item.url" :target="item.target == 'interno' ? '_self' : '_blank'" type="is-dark" outlined
            >CONOCE MÁS</b-button
          >
          <div class="section-divider"></div>
        </div>
      </b-carousel-item>
      <template v-if="indicatorImages" #indicators="props">
        <b-image class="al image" :src="items[props.i].image" :title="props.i"></b-image>
      </template>
    </b-carousel>
  </div>
</template>

<script>
import BannerImage1 from '@/assets/images/banners/mesa-de-trabajo.jpg';

export default {
  props: {
    pauseInfo: {
      type: Boolean,
      default: false,
    },
    arrowHover: {
      type: Boolean,
      default: false,
    },
    iconPrev: {
      type: String,
      default: 'chevron-left-circle-outline',
    },
    iconNext: {
      type: String,
      default: 'chevron-right-circle-outline',
    },
    iconSize: {
      type: String,
      default: 'is-large',
    },
    indicatorStyle: {
      type: String,
      default: 'is-lines',
    },
    indicatorInside: {
      type: Boolean,
      default: false,
    },
    indicator: {
      type: Boolean,
      default: false,
    },
    indicatorImages: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [
        {
          titulo: 'HAZ NEGOCIOS ENTRE SOCIOS',
          contenido:
            'Nuestra Misión es generar valor para nuestros socios mediante un enfoque estratégico de Networking. A través de nuestros Círculos Empresariales podrás expandir tu red de contactos, acceder a nuevos mercados y alcanzar tus metas profesionales.',
          image: BannerImage1,
          url: 'https://outlook.office365.com/book/ReddeNegocios1@camaradecomerciogdl.mx/',
        },
      ],
    };
  },  
};
</script>


<style lang="scss">
.banner-home {
  .carousel-item-content {
    position: relative;
  }

  .overlay-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centra verticalmente el contenido */
    width: 40%;
    height: 100%; /* Altura específica */
    word-break: break-word;
    overflow: hidden; /* Asegura que los elementos secundarios no se desborden */
    padding: 10px 0 10px 150px;
  }

  .overlay-text h3 {
    font-size: 40px;
    line-height: 40px;
    font-weight: 800;
    color: #111;
    padding: 20px 0;
  }

  .overlay-text p {
    font-size: 18px;
    font-weight: 300;
    color: #111;
    padding: 20px 0;
  }

  .overlay-text .button {
    margin-top: 10px;
    font-weight: 600;
  }

  img {
    object-fit: cover;
    max-height: 700px !important;
    object-position: top;
  }
}

@media screen and (max-width: 1023px) {
  .banner-home {
    .overlay-text {
      width: 100%;
      padding: 10px 10px 20px 10px;
      justify-content: flex-end;
    }

    .overlay-text h3 {
      font-size: 2.2rem;
    }

    .overlay-text p {
      font-size: 1.2rem;
    }

    img {
      object-fit: cover;
      max-height: max-content;
      height: 600px !important;
      object-position: top;
    }
  }
}
</style>
