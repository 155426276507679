
const initialState = {
  list: [],
  pagination: [],
};

const mutations = {
  SET_DATA(state, { key, data }) {
    state[key] = data;
  },
  INIT_STATE(state) {
    state.list = initialState.list;
    state.pagination = initialState.pagination;
  },
  EDIT_NESTED_DATA(state, { path, value }) {
    const keys = path.split('.'); // Divide el string en niveles
    let current = state;

    // Navega por los niveles excepto el último
    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i];
      if (!current[key]) {
        throw new Error(`La clave '${key}' no existe en el estado`);
      }
      current = current[key];
    }

    // Asigna el nuevo valor al nivel más profundo
    const lastKey = keys[keys.length - 1];
    if (current[lastKey] === undefined) {
      throw new Error(`La clave '${lastKey}' no existe en el estado`);
    }
    current[lastKey] = value;
  },
};

export default mutations;