import Vue from 'vue';
import Vuex from 'vuex';
import ui from './ui';
import auth from '@/modules/auth/store/index.js';
import guests from '@/modules/guests/store/index.js';
import associates from '@/modules/associates/store/index.js';
import interactions from '@/modules/interactions/store/index.js';
import statistics from '@/modules/statistics/store/index.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ui,
    auth,
    guests,
    associates,
    interactions,
    statistics,
  },
});
