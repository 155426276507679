<template>
  <div id="contact" class="contact">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-12">
          <div class="section-heading">
            <h3>CONTACTO</h3>
            <h2 class="section-title">¿Quieres participar? Llámanos</h2>
          </div>
          <div class="section-divider"></div>
        </div>
        <div class="column is-4 has-text-centered">
          <h4>Escanea el código QR o haz clic en la imagen para más información:</h4>
          <figure class="image is-128x128 is-inline-block mt-3">
            <a href="https://outlook.office365.com/book/ReddeNegocios1@camaradecomerciogdl.mx/" target="_blank">
              <img src="@/assets/images/qr.jpg" />
            </a>
          </figure>
        </div>
        <div class="column is-4">
          <blockquote class="col-3 testimonial classic">
            <h4>Luis Alberto Pérez Alvarado</h4>
            <p>reddenegocios@camaradecomerciogdl.mx <br />3338809090 ext. 2125</p>
            <footer>Gerente de Red de Negocios</footer>
            <b-button
              tag="a"
              :href="'https://outlook.office365.com/book/ReddeNegocios1@camaradecomerciogdl.mx/'"
              :target="'_blank'"
              type="is-dark"
              outlined
              >Conoce más</b-button
            >
          </blockquote>
        </div>
        <div class="column is-4">
          <div class="icon-block patr-img">
            <h4>Presentado por:</h4>

            <a href="https://www.camaradecomerciogdl.mx/#/" target="_blank">
              <img src="@/assets/images/logo-footer.jpeg" class="img-responsive" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.contact {
  padding: 75px 0;
  background-color: #f5f5f5;
  text-align: center;

  .section-divider::before {
    margin: 30px auto;
  }

  .testimonial {
    q {
      font-size: 22px;
      font-weight: 300;
    }
    footer {
      padding-top: 12px;
    }

    h4 {
      font-size: 18px;
      font-weight: 400;
      color: #111;
    }

    p {
      margin: 15px 0;
    }
  }

  .section-heading {
    padding: 0 0 15px 0;

    h3 {
      font-size: 14px;
      font-weight: bold;
      color: #ccc;
      letter-spacing: 2px;
      padding-bottom: 0;
    }

    .section-title {
      font-size: 24px;
      font-weight: 700;
      color: #111;
    }

    .section-subtitle {
      font-size: 16px;
      font-weight: 300;
      color: #9c9c9c;
    }
  }

  .button {
    margin: 35px 0;
  }

  .icon-block h4 {
    font-weight: bold;
    padding-top: 0;
    font-size: 18px;
    color: #111;
  }

  .patr-img img {
    padding: 20px 30px 0;
    max-width: 300px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>