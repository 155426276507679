// src/api/axiosInstance.js
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

instance.interceptors.request.use(
  (config) => {
    const partnerToken = localStorage.getItem('partnerToken'); // Recuperar el token de localStorage
    addDataToRequest(config, 'partnerToken', partnerToken);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function addDataToRequest(config, dataKey, dataValue) {
  // Solo agregar el parámetro si el valor es válido
  if (dataValue && dataValue !== 'undefined' && dataValue !== null) {
    if (config.method === 'post' || config.method === 'POST') {
      config.data = {
        ...config.data,
        [dataKey]: dataValue,
      };
    } else {
      config.params = {
        ...config.params,
        [dataKey]: dataValue,
      };
    }
  }
}

export default instance;
