<template>
  <section class="section-padding">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-6">
          <div class="is-flex is-justify-content-space-between is-align-items-center">
            <h1 class="title">{{ label }}</h1>
          </div>
        </div>
        <div class="column is-6">
          <div class="buttons is-pulled-right interactions-buttons">
            <b-button tag="router-link" :to="{ name: 'associates' }" type="is-primary" outlined>Nueva interacción socios </b-button>
            <b-button tag="router-link" :to="{ name: 'guests' }" type="is-primary" outlined>Nueva interacción externos </b-button>
          </div>
        </div>
        <div class="column is-12">
          <List :type="type"></List>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import List from './list.vue';

export default {
  props: {
    type: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
  components: {
    List,
  },
  data() {
    return {
      showFormModal: false,
    };
  },
  methods: {
    closeForm() {
      this.showFormModal = false;
    },
  },
};
</script>
<style>
@media screen and (max-width: 1023px) {
  .interactions-buttons .button{
    width: 100%;
    margin: 0 0 15px 0!important;
  }
}
</style>