<template>
  <div id="gallery" class="gallery container">
    <b-carousel :autoplay="false" indicator-custom :indicator-inside="false" :overlay="gallery" @click="switchGallery(true)">
      <b-carousel-item v-for="(item, i) in images" :key="i">
        <a class="image">
          <img :src="item" alt="Gallery Image" />
        </a>
      </b-carousel-item>
      <span v-if="gallery" @click="switchGallery(false)" class="modal-close is-large"></span>
      <template #indicators="props">
        <figure class="al image" :draggable="false">
          <img :draggable="false" :src="images[props.i]" :title="props.i" />
        </figure>
      </template>
    </b-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gallery: false,
      images: [],
    };
  },
  created() {
    this.loadImages();
  },
  methods: {
    switchGallery(value) {
      this.gallery = value;
      document.documentElement.classList.toggle('is-clipped', value);
    },
    loadImages() {
      const context = require.context('@/assets/images/gallery', false, /\.(jpg|jpeg|png)$/);
      this.images = context.keys().map(context);
    },
  },
};
</script>

<style lang="scss">
.gallery {
  padding: 75px 0;
}
</style>
