<template>
  <div>
    <Banners></Banners>
    <About></About>
    <Gallery></Gallery>
    <Services></Services>
    <Contact></Contact>
  </div>
</template>

<script>
import Banners from '../components/banners.vue';
import About from '../components/about.vue';
import Gallery from '../components/gallery.vue';
import Services from '../components/services.vue';
import Contact from '../components/contact.vue';

export default {
  components: {
    Banners,
    About,
    Gallery,
    Services,
    Contact,
  },
};
</script>

<style>
</style>