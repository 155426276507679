import axiosInstance from '@/api/axiosInstance';

const actions = {
  async fetchList({ commit }, queryParams) {
    try {
      const url = '/interacciones/list';
      const params = new URLSearchParams(queryParams);
      const response = await axiosInstance.get(`${url}?${params.toString()}`);

      if (response?.status === 200 && response?.data?.data) {
        const interactions = response.data.data.interactions || {};
        const list = interactions.list || [];
        const pagination = interactions.pagination || {};
        const type = interactions.type || {};

        commit('UPDATE_FIELD', { key: `${type}.list`, data: list });
        commit('UPDATE_FIELD', { key: `${type}.pagination`, data: pagination });
      } else {
        throw new Error('No se pudo obtener el listado');
      }
    } catch (error) {
      console.error('Error al obtener el listado:', error);
    }
  },
  async submitForm({ commit }, orderData) {
    try {
      const url = '/interacciones/save';
      let base64Image = null;

      if (orderData.file) {
        base64Image = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(orderData.file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }

      const payload = {
        ...orderData,
        imagen: base64Image, // Agrega la imagen en formato base64
      };

      const response = await axiosInstance.post(url, payload);

      if (response?.status === 200) {
        const data = response.data;
        commit('SET_DATA', { key: 'formSend', data: true });

        return data;
      } else {
        throw new Error('No se pudo obtener la información de la orden');
      }
    } catch (error) {
      console.error('Error al crear la petición:', error);
      throw error;
    }
  },
  async remove({ commit }, id) {
    try {
      const url = `/interacciones/destroy/${id}`;
      const response = await axiosInstance.delete(url);

      if (response?.status === 200) {
        const data = response.data;
        commit('SET_DATA', { key: 'formSend', data: true });

        return data;
      } else {
        throw new Error('No se pudo obtener la información de la orden');
      }
    } catch (error) {
      console.error('Error al crear la petición:', error);
      throw error;
    }
  },
  async searchReferableUsers({ commit }, queryParams) {
    try {
      const url = '/interacciones/search_referable_users';
      const params = new URLSearchParams(queryParams);
      const response = await axiosInstance.get(`${url}?${params.toString()}`);

      if (response?.status === 200 && response?.data?.payload) {
        const searchReferableUsers = response.data.payload.searchReferableUsers;
        commit('SET_DATA', { key: 'searchReferableUsers', data: searchReferableUsers || [] });
      } else {
        throw new Error('No se pudo obtener la información de la cartelera');
      }
    } catch (error) {
      console.error('Error al obtener la cartelera:', error);
    }
  },
};

export default actions;
