<template>
  <form id="FormularioCrud" @submit.prevent="validateForm">
    <div class="box">
      <span class="tag is-danger is-medium mb-5">Información del emisor</span>

      <div class="columns is-4">
        <div class="column is-one-quarter has-text-weight-bold">Nombre:</div>
        <div class="column">
          <b-input v-model="form.emisor_nombre" type="text" required :disabled="isDisabled"></b-input>
        </div>
      </div>

      <div class="columns is-4">
        <div class="column is-one-quarter has-text-weight-bold">Empresa:</div>
        <div class="column">
          <b-input v-model="form.emisor_empresa" required type="text" :disabled="isDisabled"></b-input>
        </div>
      </div>

      <div class="columns is-4">
        <div class="column is-one-quarter has-text-weight-bold">RFC:</div>
        <div class="column">
          <b-input v-model="form.emisor_rfc" required type="text" :disabled="isDisabled"></b-input>
        </div>
      </div>

      <div class="columns is-4">
        <div class="column is-one-quarter has-text-weight-bold">Correo electrónico:</div>
        <div class="column">
          <b-input v-model="form.emisor_email" required type="email" :disabled="isDisabled"></b-input>
        </div>
      </div>

      <div v-if="!isHiddenEmisor">
        <div class="columns is-4">
          <div class="column is-one-quarter has-text-weight-bold">Comentarios:</div>
          <div class="column">
            <b-input
              v-model="form.comentarios_emisor"
              required
              type="textarea"
              :disabled="isDisabledEmisor"
              placeholder="Escribir una síntesis de la reunión."
            ></b-input>
          </div>
        </div>

        <div class="columns is-4">
          <div class="column is-one-quarter has-text-weight-bold">Puntuación:</div>
          <div class="column">
            <b-rate v-model="form.puntuacion_emisor" icon-pack="fas" size="is-medium" spaced required :disabled="isDisabledEmisor"></b-rate>
          </div>
        </div>
      </div>
    </div>

    <div v-if="form.receptor_nombre" class="box">
      <span class="tag is-info is-medium mb-5">Información del receptor</span>

      <div class="columns is-4">
        <div class="column is-one-quarter has-text-weight-bold">Nombre:</div>
        <div class="column">
          <b-input v-model="form.receptor_nombre" type="text" required :disabled="isDisabled"></b-input>
        </div>
      </div>

      <div class="columns is-4">
        <div class="column is-one-quarter has-text-weight-bold">Empresa:</div>
        <div class="column">
          <b-input v-model="form.receptor_empresa" required type="text" :disabled="isDisabled"></b-input>
        </div>
      </div>

      <div class="columns is-4">
        <div class="column is-one-quarter has-text-weight-bold">RFC:</div>
        <div class="column">
          <b-input v-model="form.receptor_rfc" required type="text" :disabled="isDisabled"></b-input>
        </div>
      </div>

      <div class="columns is-4">
        <div class="column is-one-quarter has-text-weight-bold">Correo electrónico:</div>
        <div class="column">
          <b-input v-model="form.receptor_email" required type="email" :disabled="isDisabled"></b-input>
        </div>
      </div>

      <div v-if="!isHiddenReceptor && this.form.estatus != 'aclarar'">
        <div class="columns is-4">
          <div class="column is-one-quarter has-text-weight-bold">Comentarios:</div>
          <div class="column">
            <b-input
              v-model="form.comentarios_receptor"
              required
              type="textarea"
              :disabled="isDisabledReceptor"
              placeholder="Escribir una síntesis de la reunión."
            ></b-input>
          </div>
        </div>

        <div class="columns is-4">
          <div class="column is-one-quarter has-text-weight-bold">Puntuación:</div>
          <div class="column">
            <b-rate v-model="form.puntuacion_receptor" icon-pack="fas" size="is-medium" spaced required :disabled="isDisabledReceptor"></b-rate>
          </div>
        </div>
      </div>
    </div>

    <div v-if="form.invitado_nombre" class="box">
      <span class="tag is-info is-medium mb-5">Información del receptor</span>

      <div class="columns is-4">
        <div class="column is-one-quarter has-text-weight-bold">Nombre:</div>
        <div class="column">
          <b-input v-model="form.invitado_nombre" type="text" required :disabled="isDisabled"></b-input>
        </div>
      </div>

      <div class="columns is-4">
        <div class="column is-one-quarter has-text-weight-bold">Empresa:</div>
        <div class="column">
          <b-input v-model="form.invitado_empresa" required type="text" :disabled="isDisabled"></b-input>
        </div>
      </div>

      <div class="columns is-4">
        <div class="column is-one-quarter has-text-weight-bold">RFC:</div>
        <div class="column">
          <b-input v-model="form.invitado_rfc" required type="text" :disabled="isDisabled"></b-input>
        </div>
      </div>

      <div class="columns is-4">
        <div class="column is-one-quarter has-text-weight-bold">Correo electrónico:</div>
        <div class="column">
          <b-input v-model="form.invitado_email" required type="email" :disabled="isDisabled"></b-input>
        </div>
      </div>
    </div>

    <div class="box">
      <b-field label="Tipo de interaccion" label-position="horizontal">
        <b-select v-model="form.tipo_interaccion" required expanded placeholder="Nada seleccionado..." :disabled="isDisabled">
          <option value="venta">Venta</option>
          <option value="compra">Compra</option>
          <option value="referencia">Referencia</option>
          <option value="alianza">Alianza</option>
          <option value="business">Go Business</option>
        </b-select>
      </b-field>

      <div v-if="form.tipo_interaccion == 'referencia'" class="search">
        <b-field class="mb-3" :label="'Referenciado'" label-position="horizontal">
          <b-autocomplete
            v-model="form.selectedReferenciado"
            :data="searchReferableUsersList"
            :placeholder="'Referenciado'"
            field="empresa"
            icon-right="magnify"
            :loading="isFetching"
            :disabled="['toApprove', 'createdByMe', 'rejected', 'finalized'].includes(datavalues.formType)"
            @typing="handleTyping"
            @select="handleSelect"
            clearable
            expanded
          >
            <template slot-scope="props">
              <div class="media">
                <div class="media-content">
                  <p>
                    Tipo:
                    <b v-if="esSocio(props.option)">Socio</b>
                    <b v-else>Invitado</b>
                  </p>

                  <p>
                    Nombre:
                    <b> {{ props.option.nombre }} {{ props.option.apellido }} </b>
                  </p>

                  <p>
                    Empresa:
                    <b>
                      {{ props.option.empresa }}
                    </b>
                  </p>
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>
      </div>

      <b-field label="Fecha que se llevó la interacción" label-position="horizontal">
        <b-input
          v-model="form.fecha_interaccion"
          type="date"
          required
          :disabled="isDisabled && datavalues.formType != 'toClarify'"
        ></b-input>
      </b-field>

      <b-field v-if="!['business', 'referencia', 'alianza'].includes(form.tipo_interaccion)" label="Monto" label-position="horizontal">
        <b-input
          v-model="form.monto"
          step="0.01"
          min="0"
          type="number"
          required
          :disabled="isDisabled && datavalues.formType != 'toClarify'"
        ></b-input>
      </b-field>

      <b-field label="Estatus" label-position="horizontal">
        <b-select v-model="form.estatus" required expanded placeholder="Nada seleccionado..." :disabled="isSendButtonHidden">
          <option
            v-if="(isSendButtonHidden || datavalues.formType == 'toClarify') && (!form.invitado_id || form.invitado_id == '0')"
            value="pendiente"
          >
            Pendiente
          </option>
          <option v-if="datavalues.formType != 'toClarify'" value="aclarar">Por Aclarar</option>
          <option
            v-if="datavalues.formType !== 'toClarify' || (datavalues.formType === 'toClarify' && Number(form.invitado_id) > 0)"
            value="aceptada"
          >
            Aceptar
          </option>
          <option value="rechazada">Rechazar</option>
        </b-select>
      </b-field>

      <b-field v-if="form.estatus == 'aclarar' || ['toClarify', 'rejected', 'finalized'].includes(datavalues.formType)" label="Motivo">
        <b-input
          v-model="form.comentarios_aclarar"
          required
          type="textarea"
          placeholder="Escribir comentarios de la aclaraciónn."
          :disabled="['createdByMe', 'toClarify', 'rejected', 'finalized'].includes(datavalues.formType)"
        ></b-input>
      </b-field>
    </div>

    <b-button v-if="!isSendButtonHidden" class="is-success" type="submit" native-type="submit" label="Enviar" expanded></b-button>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Swal from 'sweetalert2';

export default {
  props: {
    datavalues: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState({
      partner: (state) => state.auth.partner,
      searchReferableUsersList: (state) => state.interactions.searchReferableUsers,
    }),
    isHiddenEmisor() {
      return ['toApprove'].includes(this.datavalues.formType);
    },
    isDisabledEmisor() {
      return ['toApprove', 'createdByMe', 'rejected', 'finalized'].includes(this.datavalues.formType);
    },
    isHiddenReceptor() {
      return (this.datavalues.formType !== 'createdByMe' && this.form.estatus === 'pendiente') || this.datavalues.formType === 'toClarify';
    },
    isDisabledReceptor() {
      return ['createdByMe', 'rejected', 'finalized', 'toClarify'].includes(this.datavalues.formType);
    },
    isSendButtonHidden() {
      return ['createdByMe', 'rejected', 'finalized'].includes(this.datavalues.formType);
    },
  },
  watch: {
    datavalues: {
      immediate: true, // Ejecuta loadFormData inmediatamente si interactions tiene valor al montarse
      handler(newinteractions) {
        this.loadFormData(newinteractions);
      },
    },
  },
  data() {
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);

    const getInitialFormState = () => ({
      id: null,
      tipo_interaccion: '',
      monto: '',
      estatus: '',
      comentarios_emisor: '',
      comentarios_receptor: '',
      puntuacion_emisor: 0,
      puntuacion_receptor: 0,
      emisor_id: '',
      emisor_nombre: '',
      emisor_apellido: '',
      emisor_empresa: '',
      emisor_rfc: '',
      emisor_email: '',
      emisor_circulo: '',
      receptor_id: '',
      receptor_nombre: '',
      receptor_apellido: '',
      receptor_empresa: '',
      receptor_rfc: '',
      receptor_email: '',
      receptor_circulo: '',
      invitado_id: '',
      invitado_nombre: '',
      invitado_apellido: '',
      invitado_empresa: '',
      invitado_rfc: '',
      invitado_email: '',
      invitado_circulo: '',
      comentarios_aclarar: '',
      fecha_interaccion: null,
      referencia: {},
      referenciado_id: '',
      referenciado_tipo: '',
      referenciado_empresa: '',
      selectedReferenciado: '',
    });

    return {
      isFetching: false,
      isDisabled: true,
      formTouched: false,
      form: {
        estatus: '',
      }, // Usa la función para inicializar el formulario
      initialFormState: getInitialFormState(), // Mantén una copia del estado inicial
      minDate: sevenDaysAgo.toISOString().split('T')[0], // Formato YYYY-MM-DD
      maxDate: today.toISOString().split('T')[0], // Formato YYYY-MM-DD
    };
  },
  methods: {
    ...mapActions({
      submitForm: 'interactions/submitForm',
      searchReferableUsers: 'interactions/searchReferableUsers',
    }),
    async loadFormData() {
      if (this.datavalues && Object.keys(this.datavalues).length) {
        Object.keys(this.initialFormState).forEach((key) => {
          this.form[key] = this.datavalues[key] || this.initialFormState[key];
        });

        this.form.puntuacion_emisor = parseFloat(this.form.puntuacion_emisor);
        this.form.puntuacion_receptor = parseFloat(this.form.puntuacion_receptor);

        if (this.datavalues.referencia) {
          this.form.selectedReferenciado = this.datavalues.referencia.empresa;
        }
      } else {
        this.form = { ...this.initialFormState };
      }
    },
    async validateForm() {
      this.formTouched = true;
      if (this.form.emisor_nombre && this.form.emisor_empresa && this.form.emisor_rfc && this.form.emisor_email) {
        await this.sendForm(); // Espera a que sendForm termine antes de continuar
      }
    },
    async sendForm() {
      const { ...rest } = this.form;

      const payload = {
        ...rest,
        socio_id: this.partner.id,
      };

      if (
        this.form.tipo_interaccion == 'referencia' &&
        (this.form.referenciado_empresa != this.form.selectedReferenciado || this.form.referenciado_id == '')
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Por favor selecciona un referenciado válido antes de enviar.',
          confirmButtonColor: '#268af7',
        });

        return;
      }

      delete payload.referenciado_empresa;
      delete payload.selectedReferenciado;

      const response = await this.submitForm(payload);

      Swal.fire({
        icon: response.status ? 'info' : 'error',
        title: response.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: true,
      });

      if (response.status) {
        this.$store.commit('interactions/UPDATE_FIELD', {
          key: `toApprove.reloadList`,
          data: true,
        });

        this.$store.commit('interactions/UPDATE_FIELD', {
          key: `rejected.reloadList`,
          data: true,
        });

        this.$store.commit('interactions/UPDATE_FIELD', {
          key: `finalized.reloadList`,
          data: true,
        });

        this.$store.commit('interactions/UPDATE_FIELD', {
          key: `toClarify.reloadList`,
          data: true,
        });

        this.closeForm();
      }
    },
    closeForm() {
      this.$emit('close-form');
    },
    esSocio(row) {
      return row && Object.prototype.hasOwnProperty.call(row, 'jefe_circulo');
    },
    async handleTyping(value) {
      const trimmedValue = value.trim();
      const lengthWithoutSpaces = trimmedValue.length;

      if (lengthWithoutSpaces >= 3) {
        const newQuery = {
          search: trimmedValue,
          emisor_id: this.form.emisor_id,
          receptor_id: this.form.receptor_id,
        };

        this.isFetching = true;

        await this.searchReferableUsers(newQuery);

        this.isFetching = false;
      }
    },
    handleSelect(option) {
      if (option?.id) {
        const referenciado_tipo = Object.prototype.hasOwnProperty.call(option, 'jefe_circulo') ? 'socio' : 'invitado';
        this.form.referenciado_id = option.id;
        this.form.referenciado_tipo = referenciado_tipo;
        this.form.referenciado_empresa = option.empresa;
      }
    },
  },
};
</script>

<style scoped>
.form-horizontal {
  max-width: 600px;
  margin: auto;
}
.stars .icon {
  cursor: pointer;
  margin-right: 0.5rem;
}
</style>
