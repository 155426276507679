<template>
  <form id="FormularioCrud" @submit.prevent="sendForm">
    <b-field label="Nombre *" label-position="horizontal">
      <b-input v-model="form.nombre" type="text" required></b-input>
    </b-field>

    <b-field label="Apellido" label-position="horizontal">
      <b-input v-model="form.apellido" required type="text"></b-input>
    </b-field>

    <b-field label="Empresa" label-position="horizontal">
      <b-input v-model="form.empresa" required type="text"></b-input>
    </b-field>

    <b-field label="RFC" label-position="horizontal">
      <b-input v-model="form.rfc" required type="text"></b-input>
    </b-field>

    <b-field label="Giro comercial">
      <b-select v-model="form.giro_comercial" placeholder="Selecciona un giro comercial" required expanded>
        <option v-for="option in giros_comerciales" :key="option" :value="option">
          {{ option }}
        </option>
      </b-select>
    </b-field>

    <div class="notification is-primary has-text-centered">
      <p>
        Si el giro comercial no aparece contactar al administrador a <br />
        <a href="mailto:reddenegocios@camaradecomerciogdl.mx"> <span class="has-text-weight-bold">reddenegocios@camaradecomerciogdl.mx</span></a>
      </p>
    </div>

    <b-field label="Correo electrónico">
      <b-input v-model="form.email" required type="email"></b-input>
    </b-field>

    <b-field label="Teléfono">
      <b-input v-model="form.telefono" required type="text"></b-input>
    </b-field>

    <b-button class="is-success" type="submit" native-type="submit" label="Guardar" expanded></b-button>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Swal from 'sweetalert2';

export default {
  props: {
    datavalues: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState({
      partner: (state) => state.auth.partner,
    }),
  },
  watch: {
    datavalues: {
      immediate: true, // Ejecuta loadFormData inmediatamente si guests tiene valor al montarse
      handler(newguests) {
        this.loadFormData(newguests);
      },
    },
  },
  data() {
    return {
      form: {},
      initialFormState: {
        id: null,
        nombre: '',
        apellido: '',
        giro_comercial: '',
        email: '',
        telefono: '',
        empresa: '',
        rfc: '',
        socio_id: null,
      },
      giros_comerciales: [
        'Administración e implementación de helpdesk y servicedesk',
        'Agencia de viajes',
        'Agencia de viajes de educación',
        'Alimentos de pulpa de tamarindo',
        'Alquiler de Transporte de Carga sin Chofer',
        'Arrendamiento de equipo de impresión',
        'Aseguradora (Seguros)',
        'Asesoria en productos apple',
        'Asesoría Financiera',
        'Asesoria legal',
        'Bancos de sangre',
        'Consultoria y capacitación empresarial',
        'Centros de estimulación',
        'Ciberseguridad',
        'Campañas de Salud para Empresas',
        'Colocación y mantenimiento de persianas',
        'Comercialización de Artículos de Papelería',
        'Comercialización de Artículos Deportivos',
        'Comercialización de Artículos Promocionales',
        'Comercialización de Autos',
        'Comercialización de Equipos Cerrados de Radio Comunicación y Control de Accesos',
        'Comercialización de Equipos de Seguridad',
        'Comercialización de Hardware',
        'Comercialización de Infraestructura en Tecnologías',
        'Comercialización de paletas y helados',
        'Comercialización de Productos de Ahorro Energético',
        'Comercialización de Productos de Cacao y Cacahuates',
        'Comercialización de Ropa',
        'Comercialización de Tequila',
        'Comercialización de Transformadores Eléctricos',
        'Comercializadores de alimentos y bebidas',
        'Comercializadores de abarrotes',
        'Compra y venta de muebles nuevos y seminuevos',
        'Construcción',
        'Consultora laboral y seguridad',
        'Consultoria en clima laboral o Recursos Humanos',
        'Reclutamiento',
        'Reclutamiento solo para personal de seguridad privada',
        'Consultoria en informatica',
        'Consultoría en Inocuidad Alimentaria y Consultores Procesos de Elaboración de Alimentos',
        'Consultoria en medio ambiente',
        'Consultoría en Protección Civil, Gestión de Riesgos y Seguridad Laboral NOM 030 STPS',
        'Consultoria en seguridad e higiene',
        'Consultoría en Sistemas de Gestión y Gobierno Corporativo',
        'Consultoría Especializada en Retail, Administración de Inventarios y de Categorías',
        'Consultoría Fiscal y contable',
        'Consultoria y servicios profesionales de la nube microsoft 365',
        'Consultoría, Implementación y Mantenimiento de ERP Odoo',
        'Contenidos y servicios auditivos',
        'Control de plagas',
        'Cosmeticos',
        'Créditos Hipotecarios',
        'Creditos hipotecarios, pymes y automotriz',
        'Cursos de Oratoria',
        'Desarrollo de bodegas en preventa',
        'Desarrollo de Inteligencia Artificial  y Desarrollo de Software',
        'SOFTWARE DE LOGISTICA',
        'Desarrollo de  Aplicaciones Web',
        'Desarrollos de Proyectos de Importación',
        'Diseño de interiores (interiorismo)',
        'Despacho de Arquitectura',
        'Diseño e Impartición de Programas de Formación para el Trabajador',
        'Distribuidor de conexiones y tuberías hidraulicas',
        'Ecommerce App',
        'Editoral y talleres literarios holisticos',
        'Elaboración de envases y empaques',
        'Equipo para fiestas',
        'Escuela de Arte y Música',
        'Estructuras metalicas',
        'Evaluaciones NOM-035',
        'Exhibidores comerciales',
        'Experiencias de pintura guiada',
        'Fabricación de muebles',
        'Fabricante de acero inoxidable',
        'Fabricante de piedra sinterizada',
        'Financiera',
        'Fisioterapia',
        'Agencia Aduanal',
        'Forwarder Logística en Transportes',
        'Gas natural',
        'GAS LP',
        'Hardware',
        'Hornos de barro',
        'Equipo Medico',
        'Huevo organico',
        'Industria artes graficas',
        'Inmobiliaria',
        'Inversiones en Remates Bancarios',
        'Inyectora de plastico',
        'Ingenieria y servicios',
        'Almacenaje',
        'Mantenimiento vehicular (Taller Automotriz)',
        'Marketing Digital',
        'Branding',
        'Membresías medicas',
        'Mix de frutas deshidratadas',
        'Paileria',
        'Paisajismo',
        'Pasteleria',
        'Pinturas',
        'Polarizados',
        'Préstamos a Colaboradores',
        'Producción de mermeladas',
        'Propelas para barcos',
        'Psicoterapia sexual',
        'Rastreo satelital',
        'Distintivos',
        'Renta de Carros',
        'Salud mental',
        'Seguridad Electrónica, Voz y Datos, Casas Inteligentes',
        'Servicios de Fotografía y Videograbación',
        'Servicios de Logística Terrestre',
        'Servicios Asesores Financieros (No inversión de Capital)',
        'Servicios funerarios',
        'Servicios Legal Fiscal (Especializado)',
        'Servicios profesionales de masajes y relajación',
        'Sistemas de Captación de Agua de Lluvia',
        'Snacks saludables',
        'Software Educativo con Inteligencia Artificial',
        'Software para Restaurantes, Puntos de Venta para Restaurantes',
        'Stand para exposiciones',
        'Suplementos alimenticios',
        'Taller de imagen profesional',
        'Tarjeta de presentación digital',
        'Tecnología para Limpieza y Sanitización Sustentable',
        'Productos de limpieza',
        'Telecomunicaciones Móviles e Internet para Empresas',
        'Terapias Alternativas',
        'Traslado de valores',
        'Venta de salsa',
        'Venta y personalización de relojes',
        'Vinagre de sidra de manzana',
        'Comercialización de alimentos y bebidas a traves de maquinas expendedoras',
        'Colegio de consultores',
        'SPA',
        'Soluciones Magneticas',
        'Escuela de ingles',
        'Carpinteria',
        'Paneles solares',
        'Prefabricados para la construcción',
        'Venta de cafe',
        'Insumos medicos',
        'Servicios de contabilidad',
      ],
    };
  },
  methods: {
    ...mapActions({
      submitForm: 'guests/submitForm',
    }),
    async loadFormData() {
      if (this.datavalues && Object.keys(this.datavalues).length) {
        const { id, nombre, apellido, giro_comercial, email, telefono, rfc, empresa } = this.datavalues;

        this.form = {
          id: id || this.initialFormState.id,
          nombre: nombre || this.initialFormState.nombre,
          apellido: apellido || this.initialFormState.apellido,
          giro_comercial: giro_comercial || this.initialFormState.giro_comercial,
          email: email || this.initialFormState.email,
          telefono: telefono || this.initialFormState.telefono,
          rfc: rfc || this.initialFormState.rfc,
          empresa: empresa || this.initialFormState.empresa,
        };
      } else {
        this.form = { ...this.initialFormState };
      }
    },
    async sendForm() {
      const { ...rest } = this.form;

      const payload = {
        ...rest,
        socio_id: this.partner.id,
      };

      const response = await this.submitForm(payload);

      this.$store.commit('guests/SET_DATA', { key: 'reloadList', data: true });

      Swal.fire({
        icon: response.status ? 'info' : 'error',
        title: response.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: true,
      });

      this.closeForm();
    },
    closeForm() {
      this.$emit('close-form');
    },
  },
};
</script>

<style scoped>
.form-horizontal {
  max-width: 600px;
  margin: auto;
}
</style>
