<template>
  <div v-if="selectedOptions.length" class="pb-5">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-12">
          <h2 class="title is-2 has-text-centered">
            Estadísticas <span v-if="selectedOptions.length">- Círculo - {{ selectedOptions[0].circulo }}</span>
          </h2>
        </div>

        <div v-if="sortedOptions.length" class="column is-12">
          <div class="field">
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="selectedOption">
                  <option v-for="option in sortedOptions" :key="option.id" :value="option.id">
                    {{ formatOption(option) }}
                  </option>
                </select>
              </div>
            </div>
            <b-button @click="handleSubmit" type="is-primary" class="my-5" expanded> Enviar </b-button>
          </div>
        </div>
      </div>

      <div v-if="!loading" class="dashboard">
        <div class="columns is-multiline">
          <div class="column is-6 box">
            <div class="chart">
              <apexchart type="bar" :options="compra.options" :series="compra.series" height="350"></apexchart>
            </div>
          </div>
          <div class="column is-6 box">
            <div class="chart">
              <apexchart type="bar" :options="venta.options" :series="venta.series" height="350"></apexchart>
            </div>
          </div>
          <div class="column is-6 box">
            <div class="chart">
              <apexchart type="bar" :options="alianza.options" :series="alianza.series" height="350"></apexchart>
            </div>
          </div>
          <div class="column is-6 box">
            <div class="chart">
              <apexchart type="bar" :options="referencia.options" :series="referencia.series" height="350"></apexchart>
            </div>
          </div>
          <div class="column is-6 box">
            <div class="chart">
              <apexchart type="bar" :options="business.options" :series="business.series" height="350"></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'Dashboard',
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      loading: false,
      selectedOption: null,
    };
  },
  computed: {
    ...mapState({
      selectedOptions: (state) => state.statistics.selectedOptions,
      compra: (state) => state.statistics.compra,
      venta: (state) => state.statistics.venta,
      alianza: (state) => state.statistics.alianza,
      referencia: (state) => state.statistics.referencia,
      business: (state) => state.statistics.business,
    }),
    sortedOptions() {
      if (!Array.isArray(this.selectedOptions) || !this.selectedOptions.length) return [];

      // Verificar si hay al menos un jefe de sección
      const hasJefeSeccion = this.selectedOptions.some((option) => option.jefe_circulo == '1');

      let options = [...this.selectedOptions];

      if (hasJefeSeccion) {
        // Agregar la opción "Todos"
        options.unshift({
          id: '99999999999999999',
          nombre: 'Todos',
          apellido: '',
          empresa: '',
        });
      }

      return options;
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.fetchOptions();

    // Definir la opción seleccionada por defecto
    const jefe = this.selectedOptions.find((option) => option.jefe_circulo == '1');
    this.selectedOption = jefe ? jefe.id : this.selectedOptions.length ? this.selectedOptions[0].id : null;

    await this.loadAsyncData();
  },
  methods: {
    ...mapActions({
      fetchCharts: 'statistics/fetchCharts',
      fetchOptions: 'statistics/fetchOptions',
    }),
    async loadAsyncData() {
      if (!this.selectedOption) return;

      this.loading = true;

      await this.fetchCharts({ option: this.selectedOption, circulo_id: this.selectedOptions[0].circulo_id });

      this.loading = false;
    },
    handleSubmit() {
      this.loading = true;
      this.loadAsyncData();
    },
    formatOption(option) {
      if (option.nombre === 'Todos') return 'TODOS LOS SOCIOS DEL CIRCULO';
      return `SOCIO: ${option.nombre.toUpperCase()} ${option.apellido.toUpperCase()} - EMPRESA: ${option.empresa.toUpperCase()}`;
    },
  },
};
</script>
