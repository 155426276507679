<template>
  <section>
    <b-sidebar
      type="is-light"
      :fullheight="fullheight"
      :fullwidth="fullwidth"
      :overlay="overlay"
      :right="right"
      v-model="open"
      @close="toggleSidebar()"
    >
      <div class="admin-sidebar">
        <div class="figure">
          <img src="@/assets/images/logo-circulos.png" />
        </div>
        <b-menu class="p-1">
          <b-menu-list label="">
            <b-menu-list label="Cuenta">
              <b-menu-item
                icon="account-edit"
                tag="router-link"
                :to="{ name: 'profile' }"
                @click.native="toggleSidebar"
                label="Mi perfil"
              ></b-menu-item>
            </b-menu-list>
            <b-menu-list label="Información">
              <b-menu-item
                icon="handshake"
                tag="router-link"
                :to="{ name: 'interactions' }"
                @click.native="toggleSidebar"
                label="Interacciones"
              ></b-menu-item>
              <b-menu-item
                icon="chart-bar"
                tag="router-link"
                :to="{ name: 'statistics' }"
                @click.native="toggleSidebar"
                label="Estadísticas"
              ></b-menu-item>
            </b-menu-list>
            <b-menu-list label="Catalogos / Interacciones">
              <b-menu-item icon="account-group" tag="router-link" :to="{ name: 'associates' }" @click.native="toggleSidebar" label="Socios"></b-menu-item>
              <b-menu-item icon="email" tag="router-link" :to="{ name: 'guests' }" @click.native="toggleSidebar" label="Externos"></b-menu-item>
            </b-menu-list>
            <b-menu-list label="Opciones">
              <b-menu-item icon="logout" @click="logout" label="Salir"></b-menu-item>
            </b-menu-list>
          </b-menu-list>
        </b-menu>
      </div>
    </b-sidebar>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      open: false,
      overlay: true,
      fullheight: true,
      fullwidth: false,
      right: false,
    };
  },
  computed: {
    ...mapState({
      menuOpen: (state) => state.ui.menuOpen,
    }),
  },
  watch: {
    menuOpen(newValue) {
      this.open = newValue;
    },
    open(newValue) {
      this.uiSetData({ key: 'menuOpen', data: newValue });
    },
  },
  methods: {
    ...mapMutations({
      uiSetData: 'ui/SET_DATA',
    }),
    toggleSidebar() {
      this.open = false;
    },
    logout() {
      Swal.fire({
        icon: 'info',
        title: 'Tu sesión ha sido cerrada correctamente.',
        confirmButtonColor: '#268af7',
      }).then(() => {
        // Eliminar todos los datos de sesión
        localStorage.removeItem('partnerToken');
        localStorage.removeItem('partnerTokenTime');

        this.$router.push({ name: 'login' });
      });
    },
  },
};
</script>

<style lang="scss">
.admin-sidebar {
  .figure {
    padding: 20px 0;
    img {
      width: 60%;
      margin: auto;
      display: block;
    }
  }
}
</style>