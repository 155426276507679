import axiosInstance from '@/api/axiosInstance';

const actions = {
  async fetchList({ commit }, queryParams) {
    try {
      const url = '/socios/list';
      const params = new URLSearchParams(queryParams);
      const response = await axiosInstance.get(`${url}?${params.toString()}`);

      if (response?.status === 200 && response?.data?.data) {
        const associates = response.data.data.associates || {};  // Verificamos que invitados exista
        const list = associates.list || [];  // Si list no existe, asignamos un array vacío
        const pagination = associates.pagination || {};  // Si pagination no existe, asignamos un objeto vacío
        const options = associates.options || {};

        // Verificamos que list sea un array antes de verificar su longitud
        if (Array.isArray(list) && (list.length === 0 || pagination.page === 1)) {
          commit('INIT_STATE');
        }

        commit('SET_DATA', { key: 'list', data: list });
        commit('SET_DATA', { key: 'pagination', data: pagination });
        commit('SET_DATA', { key: 'options', data: options });
      } else {
        throw new Error('No se pudo obtener el listado');
      }
    } catch (error) {
      console.error('Error al obtener el listado:', error);
    }
  },
  async interaction({ commit }, orderData) {
    try {
      const url = '/socios/interaction';

      const payload = { ...orderData };

      const response = await axiosInstance.post(url, payload);

      if (response?.status === 200) {
        const data = response.data;
        commit('SET_DATA', { key: 'formSend', data: true });

        return data;
      } else {
        throw new Error('No se pudo obtener la información de la orden');
      }
    } catch (error) {
      console.error('Error al crear la petición:', error);
      throw error;
    }
  },
};

export default actions;
