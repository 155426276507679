<template>
  <div class="header">
    <b-navbar class="container" centered>
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img src="@/assets/images/logo-circulos.png" />
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item tag="router-link" :to="{ name: 'home' }"> INICIO </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'home', hash: '#about' }"> ACERCA DE </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'home', hash: '#gallery' }"> GALERIA </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'home', hash: '#services' }"> OBJETIVOS </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'home', hash: '#contact' }"> CONTACTO </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'login' }"> INGRESAR </b-navbar-item>
      </template>

      <template #end>
        <b-navbar-item href="https://outlook.office365.com/book/ReddeNegocios1@camaradecomerciogdl.mx/">
          CONOCE MÁS
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'Header',
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.nav-burger {
  position: absolute;
  right: 20px;
  top: 20px;
}
</style>