import Vue from 'vue';
import App from './modules/App';
import router from './routes';
import store from './store';

import Buefy from 'buefy';
import 'animate.css';

import 'buefy/dist/buefy.css';
import languagePlugin from './locales/languagePlugin'; // Importa tu plugin de idioma

import VueApexCharts from 'vue-apexcharts';
Vue.component('apexchart', VueApexCharts);

Vue.use(languagePlugin);

Vue.use(Buefy);

import './assets/scss/themes/default.scss'

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount('#app');
