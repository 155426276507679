import HomeLayout from '@/layouts/home/index.vue';
import LoginLayout from '@/layouts/login/index.vue';
import RegisterLayout from '@/layouts/register/index.vue';
import RecoveryLayout from '@/layouts/register/index.vue';
import PartnersLayout from '@/layouts/partners/index.vue';

import Home from "./home";
import Login from "@/modules/login/pages/index.vue";
import Register from "@/modules/register/pages/index.vue";
import Recovery from "@/modules/recovery/pages/index.vue";
import Partners from "@/modules/partners/pages/index.vue";
import Profile from "@/modules/partners/pages/profile.vue";
import Guests from "@/modules/guests/pages/index.vue";
import Associates from "@/modules/associates/pages/index.vue";
import Statistics from "@/modules/statistics/pages/index.vue";
import Interactions from "@/modules/interactions/pages/index.vue";

export default [
  {
    path: '/',
    component: HomeLayout,
    children: [
      ...Home,
    ]
  },
  {
    path: '/login',
    component: LoginLayout,
    children: [
      {
        path: '',
        component: Login,
        name: 'login',
      },
    ]
  },
  {
    path: '/register',
    component: RegisterLayout,
    children: [
      {
        path: '',
        component: Register,
        name: 'register',
      },
    ]
  },
  {
    path: '/recovery',
    component: RecoveryLayout,
    children: [
      {
        path: '',
        component: Recovery,
        name: 'recovery',
      },
    ]
  },
  {
    path: '/partners',
    component: PartnersLayout,
    children: [
      {
        path: '',
        component: Partners,
        name: 'partners',
      },
      {
        path: 'profile',
        component: Profile,
        name: 'profile',
      },
      {
        path: 'external',
        component: Guests,
        name: 'guests',
      },
      {
        path: 'associates',
        component: Associates,
        name: 'associates',
      },
      {
        path: 'statistics',
        component: Statistics,
        name: 'statistics',
      },
      {
        path: 'interactions',
        component: Interactions,
        name: 'interactions',
      },
    ]
  },
];
