
const initialState = {
  list: [],
  pagination: [],
};

const mutations = {
  SET_DATA(state, { key, data }) {
    state[key] = data;
  },
  INIT_STATE(state) {
    state.list = initialState.list;
    state.pagination = initialState.pagination;
  },
};

export default mutations;