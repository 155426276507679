<template>
  <footer class="container">
    <div class="landing-footer">
      <p id="copyright">
        ® Cámara de Comercio Servicios y Turísmo de Guadalajara.
      </p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss">
.landing-footer,
.landing-footer p,
.landing-footer a {
  font-size: 12px;
  font-weight: normal;
  color: #999;
}

.landing-footer {
  clear: both;
  padding: 20px 0;
}
</style>