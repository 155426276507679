<template>
  <div>
    <form @submit.prevent="register" class="login-form">
      <h3 class="is-size-5 has-text-weight-bold has-text-centered mb-5">REGISTRO</h3>
      <b-field label="Número de socio">
        <b-input v-model="form.socio" required type="text"></b-input>
      </b-field>

      <b-field label="RFC">
        <b-input v-model="form.rfc" @input="toUpperCase" required type="text"></b-input>
      </b-field>

      <b-field label="Nombre">
        <b-input v-model="form.name" required type="text"></b-input>
      </b-field>

      <b-field label="Apellido">
        <b-input v-model="form.lastName" required type="text"></b-input>
      </b-field>

      <b-field label="Teléfono">
        <b-input v-model="form.phone" required type="text"></b-input>
      </b-field>

      <b-field label="Correo electrónico">
        <b-input v-model="form.email" required type="email"></b-input>
      </b-field>

      <b-field label="Contraseña" message="Debe tener al menos 8 caracteres, incluir 1 mayúscula, 1 minúscula y 1 número.">
        <b-input v-model="form.password" required type="password" password-reveal></b-input>
      </b-field>

      <b-button class="is-primary" type="submit" native-type="submit" label="Enviar" expanded :loading="isLoading"></b-button>

      <b-button class="mt-3" type="is-default" tag="router-link" :to="{ name: 'login' }" label="Login" expanded></b-button>
    </form>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      form: {
        socio: '',
        rfc: '',
        name: '',
        lastName: '',
        phone: '',
        email: '',
        password: '',
      },
      sendForm: false,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions({
      registerPartner: 'auth/register',
    }),
    toUpperCase() {
      this.form.rfc = this.form.rfc.toUpperCase();
    },
    isPasswordValid(password) {
      // Expresión regular para al menos una mayúscula, una minúscula y un número
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
      return regex.test(password);
    },
    async register() {
      this.isLoading = true;

      if (!this.isPasswordValid(this.form.password)) {
        Swal.fire({
          icon: 'error',
          title: 'La contraseña debe contener al menos una letra mayúscula, una letra minúscula y un número.',
          confirmButtonColor: '#268af7',
        });
        this.isLoading = false;
        return;
      }

      const response = await this.registerPartner({
        socio: this.form.socio,
        rfc: this.form.rfc,
        nombre: this.form.name,
        apellido: this.form.lastName,
        telefono: this.form.phone,
        email: this.form.email,
        password: this.form.password,
      });

      Swal.fire({
        icon: response.status ? 'success' : 'error',
        title: response.msg,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: true,
      });

      this.isLoading = false;

      if (response.status) {
        this.$router.push({ name: 'partners' });
      }
    },
  },
};
</script>
