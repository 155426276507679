<template>
  <div>
    <form @submit.prevent="login" class="login-form">
      <h3 class="is-size-5 has-text-weight-bold has-text-centered mb-5">ACCESO</h3>

      <b-field label="Correo electrónico">
        <b-input v-model="form.email" required type="email"></b-input>
      </b-field>

      <b-field label="Contraseña" message="Debe tener al menos 8 caracteres, incluir 1 mayúscula, 1 minúscula y 1 número.">
        <b-input v-model="form.password" required type="password" password-reveal></b-input>
      </b-field>

      <b-button class="is-primary" type="submit" native-type="submit" label="Enviar" expanded :loading="isLoading"></b-button>

      <b-button class="mt-3" type="is-info" tag="router-link" :to="{ name: 'recovery' }" label="Recuperar contraseña" expanded></b-button>

      <b-button class="mt-3" type="is-default" tag="router-link" :to="{ name: 'register' }" label="Registrate" expanded></b-button>
    </form>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      form: {
        email: '', //salvador.navarro401@gmail.com
        password: '', //12345abcABC
      },
      sendForm: false,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions({
      loginPartner: 'auth/login',
    }),
    toUpperCase() {
      this.form.rfc = this.form.rfc.toUpperCase();
    },
    isPasswordValid(password) {
      // Expresión regular para al menos una mayúscula, una minúscula y un número
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
      return regex.test(password);
    },
    async login() {
      this.isLoading = true;

      if (!this.isPasswordValid(this.form.password)) {
        Swal.fire({
          icon: 'error',
          title: 'La contraseña debe contener al menos una letra mayúscula, una letra minúscula y un número.',
          confirmButtonColor: '#268af7',
        });
        this.isLoading = false;
        return;
      }

      const response = await this.loginPartner({
        email: this.form.email,
        password: this.form.password,
      });

      Swal.fire({
        icon: response.status ? 'success' : 'error',
        title: response.msg,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: true,
      });

      this.isLoading = false;

      if (response.status) {
        this.$router.push({ name: 'partners' });
      }
    },
  },
};
</script>
