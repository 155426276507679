const state = {
  formSend: false,
  selectedOptions: [],
  compra: {
    options: {
      chart: {
        id: 'compras-chart',
      },
      title: {
        text: 'Compras Anuales',
      },
      colors: ['#007BFF', '#F39C12'],
      xaxis: {
        categories: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      },
      yaxis: [
        {
          title: {
            text: '',
          },
        },
        {
          opposite: true,
          title: {
            text: '',
          },
          labels: {
            formatter: function (value) {
              return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(value);
            },
          },
        },
      ],
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: 'Compras',
        type: 'column',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: 'Monto',
        type: 'column',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  },
  venta: {
    options: {
      chart: {
        id: 'ventas-chart',
      },
      title: {
        text: 'Ventas Anuales (0)',
      },
      xaxis: {
        categories: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      },
      colors: ['#28A745', '#F39C12'], // Verde y Monto color
      yaxis: [
        {
          title: {
            text: '',
          },
        },
        {
          opposite: true,
          title: {
            text: '',
          },
          labels: {
            formatter: function (value) {
              return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(value);
            },
          },
        },
      ],
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: 'Ventas',
        type: 'column',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: 'Monto',
        type: 'column',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  },
  alianza: {
    options: {
      chart: {
        id: 'alianzas-chart',
      },
      title: {
        text: 'Alianzas Anuales (0)',
      },
      xaxis: {
        categories: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      },
      colors: ['#E83E8C', '#F39C12'], // Rosa y Monto color
      yaxis: [
        {
          title: {
            text: '',
          },
        },
        {
          opposite: true,
          title: {
            text: '',
          },
          labels: {
            formatter: function (value) {
              return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(value);
            },
          },
        },
      ],
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: 'Alianzas',
        type: 'column',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: 'Monto',
        type: 'column',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  },
  referencia: {
    options: {
      chart: {
        id: 'referencias-chart',
      },
      title: {
        text: 'Referencias Anuales (0)',
      },
      xaxis: {
        categories: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      },
      colors: ['#800020', '#F39C12'], // Tinto y Monto color
      yaxis: [
        {
          title: {
            text: '',
          },
        },
        {
          opposite: true,
          title: {
            text: '',
          },
          labels: {
            formatter: function (value) {
              return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(value);
            },
          },
        },
      ],
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: 'Referencias',
        type: 'column',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: 'Monto',
        type: 'column',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  },
  business: {
    options: {
      chart: {
        id: 'business-chart',
      },
      title: {
        text: 'Go business Anuales (0)',
      },
      xaxis: {
        categories: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      },
      colors: ['#6F42C1', '#F39C12'], // Morado y Monto color
      yaxis: [
        {
          title: {
            text: '',
          },
        },
        {
          opposite: true,
          title: {
            text: '',
          },
          labels: {
            formatter: function (value) {
              return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(value);
            },
          },
        },
      ],
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: 'Business',
        type: 'column',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: 'Monto',
        type: 'column',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  },
};

export default state;
