<template>
  <section class="hero is-gray is-fullheight-with-navbar">
    <div class="hero-body">
      <div class="container has-text-centered">
        <h1 class="title">¡Bienvenido a tu Panel de Administración!</h1>
        <h2 class="subtitle">Administra fácilmente tu perfil, interacciones y más.</h2>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {},
};
</script>

<style scoped>
.title {
  color: white;
  font-size: 3rem;
}

.subtitle {
  color: white;
  font-size: 1.5rem;
}

b-button {
  font-size: 1.25rem;
}
</style>
