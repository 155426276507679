<template>
  <div id="about" class="about">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-4">
          <h3>NETWORKING</h3>
          <h2 class="section-title">Únete a Círculos Empresariales y conecta con clientes potenciales</h2>
          <div class="section-divider"></div>
          <p class="section-subtitle">
            En cada sesión, expande tu red de contactos, asegura tu cadena de suministro y forja alianzas estratégicas que impulsarán tu negocio.
          </p>
        </div>
        <div class="column is-8">
          <div class="columns is-multiline">
            <div class="column is-6">
              <div class="icon-block icon-top">
                <div class="icon">
                  <i class="fa fa-users"></i>
                </div>

                <div class="icon-block-description">
                  <h4>Expande tu red de contactos</h4>
                  <p>Genera referencias de calidad y comienza a cerrar negocios.</p>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="icon-block icon-top">
                <div class="icon">
                  <i class="fa fa-link"></i>
                </div>

                <div class="icon-block-description">
                  <h4>Fortalece tus alianzas estratégicas</h4>
                  <p>
                    Genera Alianzas estratégicas, encuentra proveedores muy competitivos y las mejores oportunidades para obtener productos o
                    servicios según tus necesidades.
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="icon-block icon-top">
                <div class="icon">
                  <i class="fa fa-star"></i>
                </div>

                <div class="icon-block-description">
                  <h4>Aprende de los mejores</h4>
                  <p>
                    Relaciónate con empresarios líderes en su giro comercial, prometidos, dinámicos y directivos que toman las decisiones dentro de su
                    organización.
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="icon-block icon-top">
                <div class="icon">
                  <i class="fa fa-comments"></i>
                </div>

                <div class="icon-block-description">
                  <h4>Obtén referencias valiosas y comienza a hacer negocios</h4>
                  <p>
                    Potencializa tu red de contactos, participa en diferentes eventos de negocios y en dinámicas de Networking, visita empresas
                    líderes para conocer sus mejores prácticas comerciales.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.about {
  padding: 75px 0;

  h3 {
    font-size: 14px;
    font-weight: bold;
    color: #ccc;
    letter-spacing: 2px;
    padding-bottom: 0;
  }

  .section-title {
    font-size: 24px;
    font-weight: 700;
    color: #111;
  }

  .section-subtitle {
    font-size: 16px;
    font-weight: 300;
    color: #9c9c9c;
  }

  .icon-top {
    padding: 20px;

    .icon {
      position: relative;
      display: block;
      i {
        font-size: 30px;
        color: #d2b356;
      }
    }

    .icon-block-description {
      padding-top: 25px;
    }

    h4 {
      font-size: 18px;
      font-weight: 700;
      color: #111;
      padding-top: 0;
    }

    p {
      font-size: 15px;
      font-weight: 300;
    }
  }
}

@media screen and (max-width: 1023px) {
  .about {
    .icon-top {
      padding: 20px 0;
    }
  }
}
</style>