const state = {
  toApprove: {
    list: [],
    pagination: {
      total: 0,
    },
    reloadList: false,
  },
  createdByMe: {
    list: [],
    pagination: {
      total: 0,
    },
    reloadList: false,
  },
  rejected: {
    list: [],
    pagination: {
      total: 0,
    },
    reloadList: false,
  },
  finalized: {
    list: [],
    pagination: {
      total: 0,
    },
    reloadList: false,
  },
  toClarify: {
    list: [],
    pagination: {
      total: 0,
    },
    reloadList: false,
  },
  formSend: false,
  searchReferableUsers: [],
};

export default state;
