<template>
  <form id="FormularioCrud" @submit.prevent="sendForm">
    <b-field label="Nombre *" label-position="horizontal">
      <b-input v-model="form.nombre" type="text" required></b-input>
    </b-field>

    <b-field label="Apellido" label-position="horizontal">
      <b-input v-model="form.apellido" required type="text"></b-input>
    </b-field>

    <b-field label="Giro comercial" label-position="horizontal">
      <b-input v-model="form.giro_comercial" required type="text"></b-input>
    </b-field>

    <b-field label="Correo electrónico">
      <b-input v-model="form.email" required type="email"></b-input>
    </b-field>

    <b-field label="Teléfono">
      <b-input v-model="form.telefono" required type="text"></b-input>
    </b-field>

    <b-button class="is-success" type="submit" native-type="submit" label="Guardar" expanded></b-button>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Swal from 'sweetalert2';

export default {
  props: {
    datavalues: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState({
      partner: (state) => state.auth.partner,
    }),
  },
  watch: {
    datavalues: {
      immediate: true, // Ejecuta loadFormData inmediatamente si guests tiene valor al montarse
      handler(newguests) {
        this.loadFormData(newguests);
      },
    },
  },
  data() {
    return {
      form: {},
      initialFormState: {
        id: null,
        nombre: '',
        apellido: '',
        giro_comercial: '',
        email: '',
        telefono: '',
        socio_id: null,
      },
    };
  },
  methods: {
    ...mapActions({
      submitForm: 'guests/submitForm',
    }),
    async loadFormData() {
      if (this.datavalues && Object.keys(this.datavalues).length) {
        const { id, nombre, apellido, giro_comercial, email, telefono } = this.datavalues;

        this.form = {
          id: id || this.initialFormState.id,
          nombre: nombre || this.initialFormState.nombre,
          apellido: apellido || this.initialFormState.apellido,
          giro_comercial: giro_comercial || this.initialFormState.giro_comercial,
          email: email || this.initialFormState.email,
          telefono: telefono || this.initialFormState.telefono,
        };
      } else {
        this.form = { ...this.initialFormState };
      }
    },
    async sendForm() {
      const { ...rest } = this.form;

      const payload = {
        ...rest,
        socio_id: this.partner.id,
      };

      const response = await this.submitForm(payload);

      this.$store.commit('guests/SET_DATA', { key: 'reloadList', data: true });

      Swal.fire({
        icon: response.status ? 'info' : 'error',
        title: response.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: true,
      });

      this.closeForm();
    },
    closeForm() {
      this.$emit('close-form');
    },
  },
};
</script>

<style scoped>
.form-horizontal {
  max-width: 600px;
  margin: auto;
}
</style>
