<template>
  <section class="login hero is-white is-halfheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-6 is-offset-3">
            <div class="box">
              <Form />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Form from '../components/form.vue';

export default {
  name: 'Login',
  components: {
    Form,
  },
  data() {
    return {};
  },
  computed: {},
};
</script>