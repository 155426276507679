<template>
  <div class="header">
    <b-navbar class="container" centered>
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img src="@/assets/images/logo-circulos.png" />
        </b-navbar-item>
      </template>

      <template #start>
      </template>

      <template #end>
        <b-navbar-item tag="router-link" :to="authLink">
          {{ authText }}
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'Header',
  computed: {
    authLink() {
      return  { name: 'login' };
    },
    authText() {
      return  'LOGIN';
    },
  },
};
</script>

<style scoped>
.nav-burger {
  position: absolute;
  right: 20px;
  top: 20px;
}
</style>
