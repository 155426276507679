<script>
import { mapState } from 'vuex';
import Loading from '@/components/common/loading';
import Header from './header.vue';
import Footer from './footer.vue';

export default {
  name: 'lOGINLayout',
  components: {
    Loading,
    Header,
    Footer,
  },
  data() {
    return {
      sessionInterval: null,
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.ui.isMobile,
    }),
  },
  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
    setInterval(this.validSession, 60000);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    checkMobile() {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 1023;
      this.$store.commit('ui/SET_DATA', { key: 'isMobile', data: isMobile });
    },
  },
};
</script>

<template>
  <div class="main-admin-layout">
    <Header></Header>
    <main class="admin-content">
      <router-view />
    </main>
    <Footer></Footer>
    <Loading></Loading>
  </div>
</template>
