<template>
  <div>
    <!-- Campo de búsqueda -->
    <b-field label="Buscar">
      <b-input
        v-model="searchQuery"
        placeholder="Buscar..."
        type="search"
        icon-right-clickable
        expanded
        @icon-right-click="searchQuery = ''"
        @input="reloadSearch"
        @keydown.native.enter="loadAsyncData"
      >
      </b-input>

      <b-button @click="loadAsyncData" type="is-primary" class="ml-2"> Buscar </b-button>
    </b-field>

    <!-- Tabla -->
    <b-table
      :data="data"
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      :loading="loading"
      :per-page="perPage"
      :total="total"
      backend-pagination
      backend-sorting
      paginated
      @page-change="onPageChange"
      @sort="onSort"
      filters-change
    >
      <b-table-column field="empresa" label="Empresa" sortable v-slot="props">
        {{ props.row.empresa.toUpperCase() }}
      </b-table-column>
      <b-table-column field="nombre" label="Nombre" sortable v-slot="props">
        {{ props.row.nombre + ' ' + props.row.apellido }}
      </b-table-column>
      <b-table-column field="giro_comercial" label="Giro" sortable v-slot="props">
        {{ props.row.giro_comercial }}
      </b-table-column>
      <b-table-column field="rfc" label="RFC" sortable v-slot="props">
        <span v-if="partner.id == props.row.socio_id"> {{ props.row.rfc }}</span>
      </b-table-column>
      <b-table-column field="email" label="Correo" sortable v-slot="props">
        <span v-if="partner.id == props.row.socio_id"> {{ props.row.email }}</span>
      </b-table-column>
      <b-table-column field="telefono" label="Telefono" sortable v-slot="props">
        <span v-if="partner.id == props.row.socio_id"> {{ props.row.telefono }}</span>
      </b-table-column>

      <b-table-column field="acciones" label="Acciones" v-slot="props">
        <div class="buttons">
          <b-button @click="openinteractionModal(props.row)" type="is-info" icon-left="handshake" size="is-small" title="Interaccion"></b-button>
          <!--           <b-button @click="openModal(props.row)" type="is-primary" icon-left="eye" size="is-small" title="Ver"></b-button> -->
        </div>
      </b-table-column>
    </b-table>

    <div class="modal form-guests-modal" :class="{ 'is-active': showFormModal }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <h2 class="modal-card-title">Invitado</h2>
          <button @click="showFormModal = false" class="delete" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <Form :datavalues="formModalData" @close-form="closeForm"></Form>
        </section>
        <footer class="modal-card-foot is-justify-content-flex-end"></footer>
      </div>
    </div>

    <div class="modal form-interaction-modal" :class="{ 'is-active': showFormInteractionModal }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <h2 class="modal-card-title">Interaccion</h2>
          <button @click="showFormInteractionModal = false" class="delete" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <Interaction :datavalues="formInteractionModalData" @close-form="closeForm"></Interaction>
        </section>
        <footer class="modal-card-foot is-justify-content-flex-end"></footer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Form from './form.vue';
import Interaction from './interaction.vue';
import Swal from 'sweetalert2';

export default {
  components: {
    Form,
    Interaction,
  },
  data() {
    return {
      data: [],
      total: 0,
      loading: false,
      sortField: 'id',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      page: 1,
      perPage: 20,
      searchQuery: '',
      showFormModal: false,
      formModalData: {},
      showFormInteractionModal: false,
      formInteractionModalData: {},
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.loadAsyncData();
  },
  computed: {
    ...mapState({
      guests: (state) => state.guests.list,
      pagination: (state) => state.guests.pagination,
      reload: (state) => state.guests.reloadList,
      partner: (state) => state.auth.partner,
    }),
  },
  watch: {
    async reload(newValue) {
      if (newValue) {
        await this.loadAsyncData();
      }
    },
  },
  methods: {
    ...mapActions({
      fetchList: 'guests/fetchList',
      remove: 'guests/remove',
    }),
    async loadAsyncData() {
      this.loading = true;

      const params = {
        page: this.page,
        size: this.perPage,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
        search: this.searchQuery.toLowerCase(),
      };

      await this.fetchList(params);

      this.data = this.guests;
      this.total = this.pagination.total;

      this.loading = false;
      this.$store.commit('guests/SET_DATA', { key: 'reloadList', data: false });
    },
    async onPageChange(page) {
      this.page = page;
      await this.loadAsyncData();
    },
    async onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      await this.loadAsyncData();
    },
    async reloadSearch() {
      if (this.searchQuery.length == 0) {
        await this.loadAsyncData();
      }
    },
    openModal(data) {
      this.showFormModal = true;
      this.formModalData = data;
    },
    openinteractionModal(data) {
      this.showFormInteractionModal = true;
      this.formInteractionModalData = data;
    },
    closeForm() {
      this.showFormModal = false;
      this.showFormInteractionModal = false;
    },
    async deleteRow(id) {
      const result = await Swal.fire({
        title: '¿Estás seguro?',
        text: 'No podrás recuperar este registro después de eliminarlo.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, borrar',
        cancelButtonText: 'Cancelar',
      });

      if (result.isConfirmed) {
        const response = await this.remove(id);

        Swal.fire({
          icon: response.status ? 'success' : 'error',
          title: response.message,
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: true,
        });

        await this.loadAsyncData();
      }
    },
  },
};
</script>
