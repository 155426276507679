<template>
  <div>
    <div class="columns is-align-items-end">
      <div class="column is-4">
        <b-field label="Filtrar por rango de fechas" class="range-filter">
          <b-datepicker
            placeholder="Selecciona un rango de fechas"
            v-model="dates"
            range
            :min-date="minDate"
            :max-date="maxDate"
            :mobile-native="false"
          >
          </b-datepicker>
        </b-field>
      </div>
      <div v-if="type == 'createdByMe'" class="column is-4">
        <b-field label="Tipo de interacción" class="interaction-filter">
          <b-select placeholder="Selecciona un tipo de interacción" expanded clearable v-model="tipo_interaccion">
            <option :value="'compra'" :key="'compra'">COMPRA</option>
            <option :value="'venta'" :key="'venta'">VENTA</option>
            <option :value="'business'" :key="'business'">GO BUSINESS</option>
            <option :value="'referencia'" :key="'referencia'">REFERENCIA</option>
            <option :value="'alianza'" :key="'alianza'">ALIANZA</option>
          </b-select>
        </b-field>
      </div>

      <div class="column">
        <b-field label="Buscar" class="search-filter">
          <b-input
            v-model="searchQuery"
            placeholder="Buscar..."
            type="search"
            icon-right-clickable
            expanded
            @icon-right-click="searchQuery = ''"
            @input="reloadSearch"
            @keydown.native.enter="loadAsyncData"
          />
        </b-field>
      </div>

      <div class="column is-narrow">
        <b-button @click="loadAsyncData" type="is-primary"> Buscar </b-button>
      </div>
    </div>

    <!-- Tabla -->
    <b-table
      :data="data"
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      :loading="loading"
      :per-page="perPage"
      :total="total"
      :mobile-cards="false"
      backend-pagination
      backend-sorting
      paginated
      @page-change="onPageChange"
      @sort="onSort"
      filters-change
      striped
    >
      <b-table-column field="creado" label="Interacción" sortable v-slot="props">
        <strong> Tipo: </strong>
        {{
          props.row.tipo_interaccion === 'compra' && partner.id === props.row.receptor_id
            ? 'VENTA'
            : props.row.tipo_interaccion === 'venta' && partner.id === props.row.receptor_id
            ? 'COMPRA'
            : props.row.tipo_interaccion === 'business'
            ? 'GO BUSINESS'
            : props.row.tipo_interaccion.toUpperCase()
        }}
        <br />
        <strong> Fecha: </strong> {{ formatDate(props.row.fecha_interaccion) }}
        <br />
        <strong> Creado: </strong> {{ formatDate(props.row.creado) }}
        <br />
        <p v-if="props.row.monto > 0"><strong> Monto: </strong> {{ formatMonto(props.row.monto) }}</p>
      </b-table-column>

      <b-table-column field="emisor_id" label="Emisor" sortable v-slot="props">
        <strong> Socio: </strong> {{ props.row.emisor_nombre + ' ' + props.row.emisor_apellido }} <br />
        <strong> RFC: </strong> {{ props.row.emisor_rfc }} <br />
        <strong> Empresa: </strong> {{ props.row.emisor_empresa }} <br />
        <strong v-if="props.row.emisor_circulo"> Círculo: </strong> {{ props.row.emisor_circulo }}
      </b-table-column>
      <b-table-column field="receptor_id" label="Receptor" sortable v-slot="props">
        <template v-if="props.row.receptor_nombre">
          <strong> Socio: </strong> {{ props.row.receptor_nombre + ' ' + props.row.receptor_apellido }} <br />
          <strong> RFC: </strong> {{ props.row.receptor_rfc }} <br />
          <strong> Empresa: </strong> {{ props.row.receptor_empresa }} <br />
          <strong v-if="props.row.receptor_circulo"> Círculo: </strong> {{ props.row.receptor_circulo }}
        </template>
        <template v-else-if="props.row.invitado_nombre">
          <strong> Invitado: </strong> {{ props.row.invitado_nombre + ' ' + props.row.invitado_apellido }} <br />
          <strong> RFC: </strong> {{ props.row.invitado_rfc }} <br />
          <strong> Empresa: </strong> {{ props.row.invitado_empresa }} <br />
          <strong> Giro: </strong> {{ props.row.invitado_giro }}
        </template>
        <template v-else> Sin información disponible </template>
      </b-table-column>

      <b-table-column field="estatus" label="Estatus" sortable v-slot="props">
        {{ props.row.estatus.toUpperCase() }}
      </b-table-column>

      <b-table-column field="id" label="Acciones" v-slot="props">
        <div class="buttons">
          <b-button @click="openModal(props.row)" type="is-info" icon-left="handshake" size="is-small" title="Interaccion"></b-button>
        </div>
      </b-table-column>
    </b-table>

    <div v-if="showFormModal" class="modal form-Interactions-modal" :class="{ 'is-active': showFormModal }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <h2 class="modal-card-title">Interacción</h2>
          <button @click="showFormModal = false" class="delete" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <Form :key="formKey" :datavalues="formModalData" @close-form="closeForm"></Form>
        </section>
        <footer class="modal-card-foot is-justify-content-flex-end"></footer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Form from './form.vue';
import Swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/es';

export default {
  props: {
    type: {
      type: String,
      required: false,
    },
  },
  components: {
    Form,
  },
  data() {
    return {
      formKey: 0,
      data: [],
      total: 0,
      loading: false,
      sortField: 'creado',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      page: 1,
      perPage: 20,
      searchQuery: '',
      showFormModal: false,
      formModalData: {},
      refreshInterval: null,
      tipo_interaccion: '',
      dates: [],
      minDate: moment().subtract(1, 'year').startOf('year').toDate(),
      maxDate: moment().toDate(),
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.loadAsyncData();

    // Configurar el intervalo para ejecutar loadAsyncData cada 2 minutos
    if (this.type == 'toApprove' || this.type == 'toClarify') {
      this.refreshInterval = setInterval(() => {
        this.loadAsyncData();
      }, 0.3 * 60 * 1000); // 18 segundos
    }
  },
  beforeDestroy() {
    // Limpiar el intervalo cuando el componente se destruya
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  },
  computed: {
    ...mapState({
      interactionsState: (state) => state.interactions,
      partner: (state) => state.auth.partner,
    }),
    interactions() {
      return this.interactionsState[this.type]?.list || [];
    },
    pagination() {
      return this.interactionsState[this.type]?.pagination || {};
    },
    reload() {
      return this.interactionsState[this.type]?.reloadList || false;
    },
    state() {
      return this.interactionsState[this.type] || false;
    },
  },
  watch: {
    async reload(newValue) {
      if (newValue) {
        await this.loadAsyncData();
      }
    },
  },
  methods: {
    ...mapActions({
      fetchList: 'interactions/fetchList',
      remove: 'interactions/remove',
      getPartner: 'auth/get',
    }),
    async loadAsyncData() {
      this.loading = true;

      await this.getPartner();

      let formattedDates = [];
      if (this.dates && this.dates.length > 0) {
        formattedDates = this.dates.map((date) => moment(date).format('YYYY-MM-DD'));
      }

      const params = {
        page: this.page,
        size: this.perPage,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
        search: this.searchQuery.toLowerCase(),
        type: this.type,
        socio_id: this.partner.id,
        tipo_interaccion: this.tipo_interaccion,
        dates: formattedDates,
      };

      await this.fetchList(params);

      this.data = this.interactions;
      this.total = this.pagination.total;

      this.loading = false;

      this.$store.commit('interactions/UPDATE_FIELD', {
        key: `${this.type}.reloadList`,
        data: false,
      });
    },
    async onPageChange(page) {
      this.page = page;
      await this.loadAsyncData();
    },
    async onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      await this.loadAsyncData();
    },
    async reloadSearch() {
      if (this.searchQuery.length == 0) {
        await this.loadAsyncData();
      }
    },
    openModal(data) {
      this.formKey++;
      this.showFormModal = true;

      const params = {
        formType: this.type,
        ...data,
      };

      this.formModalData = params;
    },
    closeForm() {
      this.showFormModal = false;
    },
    async deleteRow(id) {
      const result = await Swal.fire({
        title: '¿Estás seguro?',
        text: 'No podrás recuperar este registro después de eliminarlo.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, borrar',
        cancelButtonText: 'Cancelar',
      });

      if (result.isConfirmed) {
        const response = await this.remove(id);

        Swal.fire({
          icon: response.status ? 'success' : 'error',
          title: response.message,
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: true,
        });

        await this.loadAsyncData();
      }
    },
    formatDate(date) {
      if (!date) return 'Sin fecha';
      const formatted = moment(date).format('dddd, D [de] MMMM [de] YYYY, h:mm A');
      return formatted.charAt(0).toUpperCase() + formatted.slice(1); // Mayúscula inicial
    },
    formatMonto(monto) {
      return (
        new Intl.NumberFormat('es-MX', {
          style: 'currency',
          currency: 'MXN',
          currencyDisplay: 'symbol', // Muestra el símbolo del peso ($)
        })
          .format(monto)
          .replace('MX$', '') + ' MXN'
      );
    },
  },
};
</script>

<style>
@media screen and (max-width: 1023px) {
  .range-filter {
    margin-top: 100px;
  }
}
</style>