<script>
import { mapState } from 'vuex';
import Loading from '@/components/common/loading';
import Header from './header.vue';
import Footer from './footer.vue';

export default {
  name: 'HomeLayout',
  components: {
    Loading,
    Header,
    Footer,
  },
  data() {
    return {
      sessionInterval: null,
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.ui.isMobile,
    }),
  },
  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
    setInterval(this.validSession, 60000);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    checkMobile() {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 1023;
      this.$store.commit('ui/SET_DATA', { key: 'isMobile', data: isMobile });
    },
  },
};
</script>

<template>
  <div class="main-admin-layout">
    <Header></Header>
    <main class="admin-content">
      <router-view />
    </main>
    <Footer></Footer>
    <Loading></Loading>
    <a id="scrollUp" href="#top" style="position: fixed; z-index: 2147483647; overflow: hidden; display: block"></a>
  </div>
</template>

<style lang="scss">
#scrollUp {
  bottom: 15px;
  right: 15px;
  width: 70px;
  height: 80px;
  margin-bottom: -10px;
  padding: 0 5px 20px 5px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  -webkit-transition: margin-bottom 150ms linear;
  -moz-transition: margin-bottom 150ms linear;
  -o-transition: margin-bottom 150ms linear;
  transition: margin-bottom 150ms linear;
}

#scrollUp:before {
  background: #ccc;
  font-family: 'fontawesome';
  font-size: 2.4em;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  color: #fff;
  width: 45px;
  height: 45px;
  display: inline-block;
  line-height: 45px;
  content: '\f106';
  opacity: 1;
}
</style>