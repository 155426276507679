<template>
  <div class="header">
    <div class="container is-widescreen">
      <div class="columns">
        <div class="column">
          <b-navbar transparent :close-on-click="true">
            <template #brand>
              <b-navbar-item tag="router-link" :to="{ name: 'home' }" class="is-hidden-desktop">
                <img src="@/assets/images/logo-circulos.png" />
              </b-navbar-item>
            </template>
            <template #start>
              <b-navbar-item tag="router-link" :to="{ name: 'home' }" class="is-hidden-touch">
                <img src="@/assets/images/logo-circulos.png" />
              </b-navbar-item>
            </template>
            <template #burger>
              <b-button @click="toggleSidebar" type="is-danger" class="nav-burger is-hidden-desktop">
                <i class="fa fa-bars" aria-hidden="true"></i>
              </b-button>
            </template>
            <template #end>
              <b-navbar-item tag="div">
                <div class="buttons">
                  <!--                   <b-button @click="logout" type="is-danger"> Salir <i class="fa fa-bars" aria-hidden="true"></i></b-button>
 -->
                  <b-button @click="toggleSidebar" type="is-danger"> <i class="fa fa-bars" aria-hidden="true"></i></b-button>
                </div>
              </b-navbar-item>
            </template>
          </b-navbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Swal from 'sweetalert2';

export default {
  name: 'Header',
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      menuOpen: (state) => state.ui.menuOpen,
    }),
  },
  mounted() {},
  beforeDestroy() {},
  watch: {},
  methods: {
    ...mapMutations({
      uiSetData: 'ui/SET_DATA',
    }),
    logout() {
      Swal.fire({
        icon: 'info',
        title: 'Tu sesión ha sido cerrada correctamente.',
        confirmButtonColor: '#268af7',
      });

      localStorage.removeItem('adminToken');
      this.setData({ key: 'admin', data: [] });
      this.$router.push({ name: 'login' });
    },
    toggleSidebar() {
      this.uiSetData({ key: 'menuOpen', data: !this.menuOpen });
    },
  },
};
</script>

<style scoped>
.nav-burger {
  position: absolute;
  right: 10px;
  top: 20px;
}
</style>