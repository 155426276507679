import axiosInstance from '@/api/axiosInstance';

const actions = {
  async fetchOptions({ commit }, queryParams) {
    try {
      const url = '/statistics/options';
      const params = new URLSearchParams(queryParams);
      const response = await axiosInstance.get(`${url}?${params.toString()}`);
  
      if (response?.status === 200 && response?.data?.payload) {
        const selectedOptions = Array.isArray(response.data.payload.options) ? response.data.payload.options : [];
  
        commit('SET_DATA', { key: 'selectedOptions', data: selectedOptions });
      } else {
        throw new Error('No se pudo obtener el listado');
      }
    } catch (error) {
      console.error('Error al obtener el listado:', error);
    }
  },  
  async fetchCharts({ commit }, queryParams) {
    try {
      const url = '/statistics/get';
      const params = new URLSearchParams(queryParams);
      const response = await axiosInstance.get(`${url}?${params.toString()}`);

      if (response?.status !== 200 || !response?.data?.payload) {
        throw new Error('No se pudo obtener el listado');
      }

      const { statistics = {} } = response.data.payload;
      const chartKeys = ['compra', 'venta', 'alianza', 'referencia', 'business'];

      chartKeys.forEach((key) => {
        const data = statistics[key] || {};

        commit('EDIT_NESTED_DATA', {
          path: `${key}.options.title`,
          value: { text: data.text || 'Sin datos' },
        });

        commit('EDIT_NESTED_DATA', {
          path: `${key}.series`,
          value: data.series || [],
        });
      });
    } catch (error) {
      console.error('Error al obtener los gráficos:', error.message || error);
    }
  }

};

export default actions;
