<template>
  <div id="services" class="services">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-12">
          <div class="section-heading">
            <h3>OBJETIVOS</h3>
            <h2 class="section-title">Hacer negocios entre socios</h2>
            <div class="section-divider"></div>
            <p class="section-subtitle">
              ¡Impulsa tu negocio! Únete a nuestra red de empresarios de la Cámara de Comercio de Guadalajara y descubre nuevas oportunidades
              comerciales. Nuestra metodología de networking te permitirá conectar con más clientes y socios estratégicos.
            </p>
          </div>
        </div>
        <div class="column is-6">
          <div class="section-heading">
            <h2 class="section-title">OPERACIÓN</h2>
            <div class="section-divider"></div>
            <figure class="image">
              <img src="@/assets/images/networking.jpg" />
            </figure>
            <p class="section-subtitle">
              Las sesiones de Círculos Empresariales se realizan de manera simultánea pero cada grupo cuenta con la asignación de un espacio
              diferente, se llevan acabo dos veces al mes y tienen una orden del día estructurada que se desarrolla en un tiempo de 2 horas. Permite
              la participación de todos los integrantes, para recibir conocimiento general y específico de la oferta de valor de cada empresa, lo que
              fomenta el conocimiento entre negocios e incrementa la posibilidad de hacer negocios.
            </p>
          </div>
        </div>
        <div class="column is-6">
          <div class="section-heading">
            <h2 class="section-title">IDEAS QUE TRANSFORMAN</h2>
            <div class="section-divider"></div>
            <figure class="image">
              <img src="@/assets/images/gates.webp" />
            </figure>
            <blockquote class="testimonial text-right bigtest">
              <q>El secreto del éxito en los negocios está en detectar hacia dónde va el mundo y llegar ahí primero</q>
              <footer>Bill Gates</footer>
            </blockquote>
          </div>
        </div>
        <!--         <div class="column is-3 is-flex is-justify-content-center is-align-items-center">
          <img src="@/assets/images/bg-task.jpg" alt="Circulos Empresariales" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.services {
  padding: 75px 0;

  img {
    max-height: 260px;
    object-fit: cover;
  }

  .testimonial {
    text-align: right;
    q {
      font-size: 22px;
      font-weight: 300;
    }
    footer {
      padding-top: 12px;
    }
  }

  .section-heading {
    padding: 0 0 15px 0;

    h3 {
      font-size: 14px;
      font-weight: bold;
      color: #ccc;
      letter-spacing: 2px;
      padding-bottom: 0;
    }

    .section-title {
      font-size: 24px;
      font-weight: 700;
      color: #111;
    }

    .section-subtitle {
      font-size: 16px;
      font-weight: 300;
      color: #9c9c9c;
    }
  }

  .button {
    margin: 35px 0;
  }
}
</style>