<script>
import { mapState, mapActions } from 'vuex';
import LoadingComponent from '@/components/common/loading';
import Header from './header.vue';
import Sidebar from './sidebar.vue';

export default {
  name: 'AdminLayout',
  components: {
    LoadingComponent,
    Header,
    Sidebar,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.ui.isMobile,
      partner: (state) => state.auth.partner,
    }),
  },
  async beforeMount() {
    await this.getPartner();
  },
  async mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    ...mapActions({
      getPartner: 'auth/get',
    }),
    checkMobile() {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 1023;
      this.$store.commit('ui/SET_DATA', { key: 'isMobile', data: isMobile });
    },
  },
};
</script>

<template>
  <div class="main-admin-layout">
    <Header></Header>
    <Sidebar></Sidebar>
    <main class="admin-content">
      <router-view />
    </main>
    <LoadingComponent></LoadingComponent>
  </div>
</template>
